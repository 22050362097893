/*
 * @Author: Fan
 * @Date: 2020-12-01 10:27:52
 * @LastEditors: Fan
 * @LastEditTime: 2020-12-02 10:19:15
 * @Description: file content
 */
import React, {useEffect, useState} from 'react';
import {Picker} from 'antd-mobile';
import client from "@/graphql/apollo-config";
import {REGION} from "../graphql/store";

const Address = (props) => {
    const {disabled, title, address, showAddress, changeData, setAddress, chooseName} = props
    const [region, setRegion] = useState([]);
    useEffect(() => {
        query();
    }, []);
    const query = async () => {
        try {
            const {data} = await client.query({
                query: REGION,
                fetchPolicy: "no-cache"
            });
            if (data) {
                console.log('REGION  =  =====',data)
                // let re = [];
                const {region} = data;
                setRegion(region);
            }
        } catch (error) {
            console.log(error)
        }
    }
    const changeAddress = (v) => {
        const province = region.filter((i) => {
            return i.value === v[0]
        })
        const city = province[0].children.filter((i) => {
            return i.value === v[1]
        })
        const area = city[0].children.filter((i) => {
            return i.value === v[2]
        })
        setAddress(province[0].label, city[0].label, area[0].label, showAddress)
    }

    return <>
        <Picker
            data={region}
            disabled={disabled}
            onChange={(v) => changeAddress(v)}
        >
            <div className="category-content_wrapper">
                <div className="title">
                    {title} <i className="red">*</i>
                </div>
                <div className="content">
                    <div className="my-input">
                        {chooseName}
                    </div>
                    <i className='iconfont' style={{transform: 'rotate(90deg)'}}>&#xe6f8;</i>
                </div>
            </div>
        </Picker>
        {
            showAddress && <div className="address-content">
        <textarea
            onChange={(e) => changeData("address", e.target.value)}
            value={address}
            disabled={disabled}
            placeholder='请输入详细地址（楼栋门牌号）'
            rows='4'/>
            </div>
        }
    </>
}

export default Address
