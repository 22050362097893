import React from "react";

/*
 * @Author: Fan
 * @Date: 2020-12-01 16:10:45
 * @LastEditors: Fan
 * @LastEditTime: 2020-12-02 10:18:53
 * @Description: file content
 */
export const WAITING = "waiting",
  LOADING = "loading",
  LOADED = "loaded",
  FAILURE = "failure";
const LoadingFooter = ({ status, onError }) => {
  const getTxt = () => {
    let txt = "下拉加载更多";
    switch (status) {
      case WAITING:
        txt = "下拉加载更多";
        break;
      case LOADING:
        txt = "加载中...";
        break;
      case LOADED:
        txt = "没有更多";
        break;
      case FAILURE:
        txt = "加载失败，点击重新加载";
        break;
      default:
        break;
    }
    return txt;
  };
  return (
    <div
      style={{ padding: 20, textAlign: "center" }}
      onClick={() => {
        if (status === FAILURE) onError();
      }}
    >
      {getTxt()}
    </div>
  );
};
export default LoadingFooter;
