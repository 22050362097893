import Cookies from "js-cookie";
import { isNonEmptyArray } from "../utils/index.js";
import { Toast } from 'antd-mobile';

const catchError = error => {
  console.log(error,"补货到错误");
  const { operation, networkError, graphQLErrors } = error;
  let errObj = { code: "", message: "" };
  if (isNonEmptyArray(graphQLErrors)) {
    errObj.code = graphQLErrors[0].code || "";
    errObj.message = graphQLErrors[0].message || "";
  } else if (
    networkError &&
    networkError.result &&
    networkError.result.errors
  ) {
    errObj.code = networkError.result.errors.code || "";
    errObj.message = networkError.result.errors.message || null;
  } else {
    errObj.statusCode = 500;
    errObj.message = "网络异常，请重试";
  }
  const err = [
    "Failed to fetch",
    "Internal Server Error",
    "Unexpected token",
    "Unrecognized token",
    "Network request failed",
  ];
  const noTipCode = [41027];
  const notLoginCode = [401, 402, 40002, "Unauthenticated", "Unauthorized", "您的账号已被禁用", "未登陆"];
  const isErrMessage = err.some(item => errObj.message.indexOf(item) !== -1);
  if (isErrMessage || errObj.statusCode === 502) {
    errObj.message = "网络出错";
  }
  if (errObj.statusCode === 504 || errObj.code === 504) {
    errObj.message = "请求超时";
  }

  const notLogin = notLoginCode.find(
    item =>
      item === errObj.code ||
      (errObj.message && errObj.message.indexOf(item) !== -1)
  );

  if (notLogin) {
    errObj.message = notLogin === 401 ? "请重新登录" : errObj.message;
    Cookies.remove('token')
    Cookies.remove('mobile')
    Cookies.remove('store_id')
    setTimeout(() => {
      window.location = "/login"
    }, 1000);
  }
  const isNoTip = noTipCode.find(item => item === errObj.code);
  if (!isNoTip) Toast.info(errObj.message);
};

export { catchError };
