/*
 * @Author: Fan
 * @Date: 2020-12-01 10:27:52
 * @LastEditors: Fan
 * @LastEditTime: 2020-12-02 17:20:52
 * @Description: file content
 */
import React, { useEffect, useState, useRef } from "react";
import { Get_ScanPayRecord_Query } from "@/graphql/store";
import client from "@/graphql/apollo-config";
import { ListView } from "antd-mobile";
import ToLogin from "@/components/ToLogin";
import LoadingFooter, {
  WAITING,
  LOADING,
  LOADED,
  FAILURE,
} from "@/components/LoadingFooter";
import "../invitation/index.less";
import "./index.less";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { YMDHMFormatDate } from "@/utils/index";
const renderItem = (rowData) => {
  return (
    <div className="item">
      <div className="item-left">
        <p className="type">
          {rowData.pay_type === 0 ? "微信支付" : "支付宝支付"}
        </p>
        <p className="no">{rowData.order_no}</p>
      </div>
      <div className="item-right">
        <p className="price">¥ {rowData.total}</p>
        <p className="time">{YMDHMFormatDate(rowData.pay_at)}</p>
      </div>
    </div>
  );
};
const Record = () => {
  const [height] = useState(() => document.documentElement.clientHeight);
  const list = useRef();
  const storeId = useRef();
  const { push } = useHistory();
  const [dataSource, setDataSource] = useState(
    new ListView.DataSource({
      dataBlob: [],
      rowHasChanged: (row1, row2) => row1 !== row2,
    })
  );
  const [loadingStatus, setStatus] = useState(WAITING);
  const [childUser, setChildUser] = useState({
    current_page: 0,
  });
  const [user, setUser] = useState(0);
  useEffect(() => {
    const store_id = Cookies.get("store_id");
    storeId.current = store_id;
    if (!store_id) {
      push("/");
      return;
    }
    init();
  }, []);

  const init = async () => {
    setStatus(LOADING);
    try {
      const { data } = await client.query({
        query: Get_ScanPayRecord_Query,
        variables: {
          store_id: storeId.current,
          limit: 20,
          page: childUser.current_page + 1,
        },
        fetchPolicy: "no-cache",
      });
      if (data) {
        const d = data.StoreOrderQr;
        setChildUser(d);
        setUser(data.user);
        setStatus(!d.has_more_pages ? LOADED : WAITING);
        const newList = list.current ? [...list.current, ...d.data] : d.data;
        list.current = newList;
        setDataSource(dataSource.cloneWithRows(newList));
      }
    } catch (error) {
      setStatus(FAILURE);
    }
  };
  const onError = () => {
    init();
  };
  const onEndReached = () => {
    if (loadingStatus === WAITING) init();
  };
  return Array.isArray(list.current) && list.current.length === 0 ? (
    <>
      <ToLogin />
      <div className="no-data">
        <img src={require("../../assets/images/empty.png")} alt="" />
        <p>该店铺暂无记录</p>
      </div>
    </>
  ) : (
    <ListView
      dataSource={dataSource}
      className="invitation-wrapper record-wrapper"
      renderHeader={() => (
        <>
          <ToLogin />
          <div className="invitation-text_wrapper">
            <span className="invitation-text">
              累计营业额：￥
              {user.total_turnover_qr}
            </span>
          </div>
        </>
      )}
      renderFooter={() => (
        <LoadingFooter status={loadingStatus} onError={onError} />
      )}
      renderRow={renderItem}
      style={{
        height: height,
        overflow: "auto",
      }}
      pageSize={10} //每次事件循环（每帧）渲染的行数
      initialListSize={20}
      scrollRenderAheadDistance={500}
      onEndReached={onEndReached}
      onEndReachedThreshold={10}
    />
  );
};

export default Record;
