import React, { useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";
import { semiHidingMobile } from "@/utils";
import { useHistory } from "react-router-dom";
import "./index.less";
/*
 * @Author: Fan
 * @Date: 2020-12-02 16:28:13
 * @LastEditors: Fan
 * @LastEditTime: 2020-12-02 17:18:42
 * @Description: file content
 */
const ToLogin = (props) => {
  const [mobile,setMobile] = useState("");
  const { push,location } = useHistory();
  useEffect(() => {
    setMobile(Cookies.get("mobile") || "");
  }, []);
  const onPress = () => {
    localStorage.removeItem('store')
    push({
      pathname: "/login",
      state: {
        from: location,
      },
    });
  };
  return (
    <div className="to-login">
      <div onClick={onPress}>
        <span className="mobile">{mobile && semiHidingMobile(mobile) || ""}</span>
        <span>切换</span>
      </div>
    </div>
  );
};
export default ToLogin;
