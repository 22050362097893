import gql from "graphql-tag";

// 基本信息
export const STORE_AUDIT_INFO = gql`
  query {
    unionpayBankCategories{
          id
          value:code
          label:name
    }
    oss {
      accessid
      host
      policy
      signature
      expire
      dir
      ossUrl
    }    
    user{
        parent_mobile
    }
    additionalInfo{
      status
      reject_reason
      auth_image{
        url
      }

    }
    storeInfo {
        id
        status
        settle_status
        name   
        short_name
        store_category_id
        store_category_name
        province
        city
        area
        address
        hot_line
        mcc_code
        mcc_name
        industry_code
        industry_name
        code
        credit_code
        business_license{
          url
        }
        business_license_valid_start
        business_license_valid_end
        house_num{
          url
        }
        checkout_counter{
          url
        }
        store_inner{
          url
        }
        corporation
        id_card_no
        id_card_valid_start
        id_card_valid_end
        id_card_front{
          url
        }
        id_card_back{
          url
        }
        id_card_hand{
          url
        }
        contact_name
        contact_card_front {
          url
        }
        contact_card_back {
          url
        }
        contact_card_address
        contact_card_valid_start
        contact_card_valid_end
        contact_id_card
        contact_mobile
        contact_email
        bank_account_name
        bank_account_id_card_no
        bank_account_type
        bank_account
        bank_name
        bank_code
        bank_branch
        bank_province
        bank_city
        bank_city_code
        bank_area
        bank_area_code
        bank_id_card_front{
          url
        }
        bank_id_card_back{
          url
        }
        bank_hand_image{
          url
        }
        opening_permit_image{
          url
        }
        bank_card_image{
          url
        }
        company_name
        mobile
        product_type
        average_spend
        lowest_delivery_price
        ali_account
        cover {
          url
        }
        images {
          url
        }
        video {
          url
        }
        notice
        email
        bank_mobile
        registered_capital
        bf_industry_code
      store_base_audit{
        id
        is_first_audit
        reject_reason
        settle_status
        name   
        short_name
        store_category_id
        store_category_name
        province
        city
        area
        address
        hot_line
        mcc_code
        mcc_name
        industry_code
        industry_name
        code
        status
        credit_code
        business_license{
          url
        }
        business_license_valid_start
        business_license_valid_end
        house_num{
          url
        }
        checkout_counter{
          url
        }
        store_inner{
          url
        }
        corporation
        id_card_no
        id_card_address
        id_card_valid_start
        id_card_valid_end
        id_card_front{
          url
        }
        id_card_back{
          url
        }
        id_card_hand{
          url
        }
        contact_name
        contact_card_front {
          url
        }
        contact_card_back {
          url
        }
        contact_card_address
        contact_card_valid_start
        contact_card_valid_end
        contact_id_card
        contact_mobile
        contact_email
        bank_account_name
        bank_account_id_card_no
        bank_account_type
        bank_account
        bank_name
        bank_code
        bank_branch
        bank_province
        bank_province_code
        bank_city
        bank_city_code
        bank_area
        bank_area_code
        bank_id_card_front{
          url
        }
        bank_id_card_back{
          url
        }
        bank_hand_image{
          url
        }
        opening_permit_image{
          url
        }
        bank_card_image{
          url
        }
        company_name
        mobile
        product_type
        average_spend
        lowest_delivery_price
        ali_account
        cover {
          url
        }
        images {
          url
        }
        video {
          url
        }
        notice
        email
        bank_mobile
        registered_capital
        bf_industry_code
      }
      store_audit{
        status
        reject_reason
        reject_type
      }
    }
    storeCategory {
      id
      name
    }
    storeIndustry {
      id
      name
      code
    }
  }
`;

// 获取类别
export const CODE = gql`
  query(
      $type: Int!
      $settle_status: Int!
    ) {
    code (
      type: $type
      settle_status: $settle_status
    ){
      code
      name
    }
  }
`;

// 修改基本信息
export const ADD_STORE_BASE = gql`
  mutation (
    $short_name: String!,
    $province: String!,
    $city: String!,
    $area: String!,
    $address: String!,
    $lng: Float!,
    $lat: Float!,
    $code: String,
    $store_category_id: Int!,
    $settle_status: Int!,
    $hot_line: String!,
    $credit_code: String,
    $business_license: String,
    $business_license_valid_start: String,
    $business_license_valid_end: String,
    $house_num: String!,
    $checkout_counter: String,
    $store_inner: String,
    $company_name: String,
    $corporation: String!,
    $mobile: String,
    $id_card_no: String!,
    $id_card_address: String!,
    $id_card_valid_start: String!,
    $id_card_valid_end: String!,
    $id_card_front: String!,
    $id_card_back: String!,
    $id_card_hand: String,
    $contact_name: String!,
    $contact_card_front: String!,
    $contact_card_back: String!,
    $contact_card_address: String!,
    $contact_card_valid_start: String!,
    $contact_card_valid_end: String!,
    $contact_id_card: String!,
    $contact_mobile: String!,
    $contact_email: String!,
    $bank_account_name: String!,
    $bank_account_id_card_no: String!,
    $bank_account_type: Int!,
    $bank_account: String!,
    $bank_name: String!,
    $mcc_code: String!,
    $industry_code: String!,
    $bank_branch: String!,
    $bank_code: String!,
    $bank_province: String!,
    $bank_city: String!,
    $bank_area: String!,
    $bank_id_card_front: String!,
    $bank_id_card_back: String!,
    $bank_hand_image: String,
    $bank_card_image: String,
    $opening_permit_image: String,
    $product_type: Int!,
    $average_spend: Float,
    $lowest_delivery_price: Float,
    $ali_account: String,
    $cover: String!,
    $images: [String]!,
    $video: String,
    $notice: String,
    $email: String,
    $bank_mobile: String,
    $registered_capital: Float,
    $bf_industry_code: String!,
  ){
      addStoreBase(
        short_name: $short_name,
        province: $province,
        city: $city,
        area: $area,
        address: $address,
        lng: $lng,
        lat: $lat,
        code: $code,
        store_category_id:$store_category_id,
        settle_status: $settle_status,
        hot_line: $hot_line,
        credit_code: $credit_code,
        business_license: $business_license,
        business_license_valid_start:$business_license_valid_start,
        business_license_valid_end:$business_license_valid_end,
        house_num: $house_num,
        mcc_code: $mcc_code,
        industry_code: $industry_code,
        checkout_counter:$checkout_counter,
        store_inner:$store_inner,
        company_name:$company_name,
        corporation: $corporation,
        mobile:$mobile,
        id_card_no: $id_card_no,
        id_card_valid_start: $id_card_valid_start,
        id_card_valid_end: $id_card_valid_end,
        id_card_front: $id_card_front,
        id_card_back: $id_card_back,
        id_card_hand: $id_card_hand,
        contact_name: $contact_name,
        contact_card_front: $contact_card_front,
        contact_card_back: $contact_card_back,
        contact_card_address: $contact_card_address,
        contact_card_valid_start: $contact_card_valid_start,
        contact_card_valid_end: $contact_card_valid_end,
        contact_id_card: $contact_id_card,
        contact_mobile: $contact_mobile,
        contact_email: $contact_email,
        bank_account_name: $bank_account_name,
        bank_account_id_card_no:$bank_account_id_card_no,
        bank_account_type:$bank_account_type,
        bank_account: $bank_account,
        bank_name: $bank_name,
        bank_branch: $bank_branch,
        bank_code: $bank_code,
        bank_province: $bank_province,
        bank_city: $bank_city,
        bank_area: $bank_area,
        bank_id_card_front: $bank_id_card_front,
        bank_id_card_back: $bank_id_card_back,
        bank_hand_image:$bank_hand_image,
        bank_card_image:$bank_card_image,
        opening_permit_image:$opening_permit_image,
        id_card_address: $id_card_address,
        product_type: $product_type,
        average_spend: $average_spend,
        lowest_delivery_price: $lowest_delivery_price,
        ali_account: $ali_account,
        cover: $cover,
        images: $images,
        video: $video,
        notice: $notice,
        email: $email,
        bank_mobile: $bank_mobile,
        registered_capital: $registered_capital,
        bf_industry_code: $bf_industry_code,
    ) {
        id
    }     
  }
`;

// 查询代理
export const AGENT = gql`
  query (
    $agent_mobile: String!
    ) {
    agent(
      agent_mobile: $agent_mobile,
    )
  }
`;

// 查询补充资料
export const ADDITIONALINFO = gql`
  query {
   oss {
      accessid
      host
      policy
      signature
      expire
      dir
      ossUrl
    }
    additionalInfo{
      verify_amount
      auth_image{
        url
      }
      user_id
      reject_reason
    }
  }
`;

// 提交补充资料
export const ADDITIONAL = gql`
  mutation (
    $settle_status: Int!
    $verify_amount: Float
    $auth_image: String
    ) {
    additional(
      settle_status: $settle_status
      verify_amount: $verify_amount
      auth_image: $auth_image
    ){
      verify_amount
      auth_image{
        url
      }
      user_id
    }
  }
`;

// oss
export const OSS = gql`
  query {
    oss {
      accessid
      host
      policy
      signature
      expire
      dir
    }
  }
`;

// 地址省市区联动查询
export const REGION = gql`
  query {
    region{
          value:id
          label:name
          children:data{
            value:id
            label:name
            children:data{
              value:id
              label:name
             }
          }
    }
  }
`;
// 银联省市联动查询
export const UNIONPAYREGIONS = gql`
  query {
    provinces:unionpayRegions(
        type:1
    ) {
          id
          parent_id
          type
          code
          parent_code
          name
    }
    citys:unionpayRegions(
        type:2
    ) {
          id
          parent_id
          type
          code
          parent_code
          name
    }
    areas:unionpayRegions(
        type:3
    ) {
          id
          parent_id
          type
          code
          parent_code
          name
    }
  }
`;
// 银联行别代码查询
export const UNIONPAYBANKCATEGORIES = gql`
  query {
    unionpayBankCategories{
          id
          value:code
          label:name
    }
  }
`;
// 银联行别代码查询
export const UNIONPAYBANK = gql`
  query (
        $city_code:String,
        $category_code:String
  ){
    unionpayBank(
        city_code:$city_code,
        category_code:$category_code
    ) {
          id
          code
          name
          city_code
          category_code
    }
  }
`;

// 图片识别
export const IMAGE2TEXT = gql`
  query ($type: Int!, $url: String!, $side: String){
    image2Text(
        type:$type,
        url: $url,
        side:$side,
    ){
        name
        company_name
        id_card_no
        id_card_address
        id_card_valid_start
        id_card_valid_end
        bank_name
        bank_account
        credit_code
        business_license_valid_start
        business_license_valid_end
    }
  }
`;

export const INVITATION_USER = gql`
  query ($username: String,
        $start_time: Int,
        $end_time: Int,
        $limit: Int
        $page: Int) {
    childUser(
      username: $username,
      start_time: $start_time,
      end_time: $end_time,
      limit: $limit,
      page: $page
    ){
      data{
        avatar{
          id,
          url,
          thumbnail{
            url
          }
        },
        username,
        bind_parent_time
      },
      total,
      per_page,
      current_page,
      from,
      to,
      last_page,
      has_more_pages
    }
  }
`;

// 扫码付款记录
export const Get_ScanPayRecord_Query = gql`
  query(
    $store_id: Int
    $order_no: String
    $created_at_start: Int
    $created_at_end: Int
    $total_start: Float
    $total_end: Float
    $actual_amount_start: Float
    $actual_amount_end: Float
    $rebate_hongbao_start: Float
    $rebate_hongbao_end: Float
    $hongbao_start: Float
    $hongbao_end: Float
    $limit: Int
    $page: Int
  ) {
    StoreOrderQr(
      store_id: $store_id
      order_no: $order_no
      created_at_start: $created_at_start
      created_at_end: $created_at_end
      total_start: $total_start
      total_end: $total_end
      actual_amount_start: $actual_amount_start
      actual_amount_end: $actual_amount_end
      rebate_hongbao_start: $rebate_hongbao_start
      rebate_hongbao_end: $rebate_hongbao_end
      hongbao_start: $hongbao_start
      hongbao_end: $hongbao_end
      limit: $limit
      page: $page
    ) {
      data {
        id
        order_no
        created_at
        actual_amount
        pay_type
        rebate_hongbao
        hongbao
        hongbao_subsidy
        total
        pay_at
      }
      total
      per_page
      current_page
      last_page
      has_more_pages
    }
    user {
      username
      total_turnover_qr
    }
  }
`;

// 商家绑定微信
export const BIND_WECHAT = gql`
  mutation (
    $wechat_code: String!
    ) {
    bindWechat(
      wechat_code: $wechat_code
    )
  }
`;

// 商家
export const STORE = gql`
  query {
    storeInfo{
      wx_income_notice
    }
  }
`;
