/*
 * @Author: Fan
 * @Date: 2020-12-01 10:27:52
 * @LastEditors: Fan
 * @LastEditTime: 2020-12-02 10:13:00
 * @Description: file content
 */
import React from 'react';
import { Steps } from 'antd-mobile';
const Step = Steps.Step;

const StepNum = (props) => {
  const a = ['预览所需材料', '填写店铺信息', '填写个人信息', '填写银行信息']
  const { step } = props

  return <Steps current={step} direction="horizontal" size={'small'}>
    {
      a.map((i,index)=>{
        return <Step
          key={index}
          icon={<div style={{
            fontSize:11,
            color:'white',
            lineHeight:'20px',
            background:step + 1 >= index + 1?'#F4981D':'#ccc',
            width:'20px',
            height:'20px',
            borderRadius:'10px'}}>{index + 1}</div>}
          title={<div style={{fontSize:11,fontWeight:'normal'}}>{i}</div>} />
      })
    }
  </Steps>
}

export default StepNum