import React from 'react';
import { Modal, List } from 'antd-mobile';

const pickerModal = (props) => {
    const {show, close, arr, title} = props

    return <>
      <Modal
        className="baseinfo-modal"
        popup
        visible={show}
        onClose={() => close()}
        animationType="slide-up"
      >
        <div className="baseinfo-modal-header">
          <span>{title}</span>
        </div>
        <List className="base-popup-list">
          {arr.map((i, index) => (
            <List.Item
              onClick={() => {
                close(i.value, i.label)
              }}
              wrap={true}
              key={index}
            >
              {i.label}
            </List.Item>
          ))}
        </List>
      </Modal>
    </>
}

export default pickerModal
