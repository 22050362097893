/*
 * @Author: cc
 * @Date: 2020-09-18 14:12:21
 * @LastEditors: cc
 * @LastEditTime: 2020-10-24 15:49:47
 * @Description: X.X
 */
import { useEffect, useRef } from 'react'

function useInterval(callback, delay) {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  });

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }

    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export default useInterval