import gql from "graphql-tag";
// 用户登录
export const LOGIN_STORE = gql`
  mutation loginStore(
    $phone: String!
    $password: String!
    $pos_code: String
  ) {
    loginStore(
      phone: $phone
      password: $password
      pos_code: $pos_code
    ) {
      remember_token
      id
      type
      mobile
      store{
        id
        product_type
      }
    }
  }
`;

// 发送短信验证码
export const SEND_SMS_CAPTCHA = gql`
  mutation captcha(
    $mobile: String!
    $type: Int!
  ) {
    captcha(
      mobile: $mobile
      type: $type
    )
  }
`;
// 创建图形验证码
export const GET_IMAGE_CAPTCHA = gql`
  query captchaImg {
    captchaImg {
      key
      img
    }
  }
`;

// 商家和代理登录注册
export const REGISTER_STORE = gql`
  mutation registerStore(
    $phone: String!
    $password: String!
    $codeType: Int!
    $code: String!
    $pos_code: String
  ) {
    registerStore(
      phone: $phone
      password: $password
      code: $code
      codeType: $codeType
      pos_code: $pos_code
    ) {
      remember_token
      mobile
      store{
        id
        product_type
      }
    }
  }
`;
// 商家和代理登录重置密码
export const RESET_PWD = gql`
  mutation resetPassword(
    $phone: String!
    $password: String!
    $type: Int!
    $codeType: Int!
    $code: String!
  ) {
    resetPassword(
      phone: $phone
      type: $type
      password: $password
      code: $code
      codeType: $codeType
    ) {
      remember_token
      mobile
      store{
        id
        product_type
      }
    }
  }
`;

// 获取配置信息
export const MY_CONFIG = gql`
  query ($name: String!){
    myConfig(name: $name)
  }
`;

export const PAGE = gql `
  query ($id: ID) {
    page(id: $id) {
      id
      type
      title
      content
    }
  }
`;

export const PAGES = gql `
  query ($type: Int) {
    pages(type: $type) {
      id
      type
      title
      content
    }
  }
`;
