import React, { useMemo, useReducer, useState, useEffect, useRef } from 'react'
import app from '../../app.json'
import { urlQuery } from "@/utils/regexp"
import { MY_CONFIG } from "@/graphql/login"
import { BIND_WECHAT,STORE } from "@/graphql/store"
import client from "@/graphql/apollo-config";
import {Toast} from "antd-mobile/lib/index";
import './index.less'

const Notice = () => {
  const [openBtn, setOpen] = useState(false)

  const init = async() =>{
    if (urlQuery('code')) {
      // 开通通知
      try {
        const {data} = await client.mutate({
          mutation: BIND_WECHAT,
          variables: {
            wechat_code: urlQuery('code')
          },
          fetchPolicy: "no-cache"
        });
        if (data && data.bindWechat){
          Toast.info('开启成功')
          setOpen(false)
        }
      } catch (error) {}
    }else{
      try {
        const {data} = await client.query({
          query: STORE,
          fetchPolicy: "no-cache"
        });
        if (data && data.storeInfo.wx_income_notice === 0){
          // 0未开通1已开通
          setOpen(true)
        }
      } catch (error) {}
    }
  }

  useEffect(()=>{
    init()
  },[])

  const open = ()=>{
    if (!openBtn) return
    const redirect_uri = encodeURI(`${app.host}/notice`)
    const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${app.app_id}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&#wechat_redirect`
    window.location.href = url
  }

  return <div className='notice-wrapper'>
    <div className='notice-text'>
      微信订单收益通知开通
      <br/>
      锁定流量微信通知开通
    </div>
    <div className='open' style={{opacity:openBtn?1:.6}} onClick={()=>open()}>
      {openBtn?'点击开启':'已开启'}</div>
  </div>
}

export default Notice