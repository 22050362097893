import React, { useState } from "react";
import { ImagePicker, Toast, Modal } from "antd-mobile";
import handleImageData from "@/utils/imageCompress.js";

const ImgPicker = (props) => {
  const { type = 'img', disabled, title, img, OSSData, changeData, showStart, max = 1 } = props;
  const [url, setUrl] = useState("");
  const [showModal, setModal] = useState(false);

  // 图片
  const changePicker = async (files) => {
    const _files = files.filter(t => !!t.file)
    if (_files.length > 0) {
      if (_files[0].file.type.indexOf("image") !== -1) {
        // let temp = [];
        // console.log(_files[0].url.length, "压缩前");
        // let compressBase64 = await handleImageData(
        //   _files[0].url,
        //   2000,
        //   _files[0].orientation
        // );
        // console.log(compressBase64.length, "压缩后");
        // temp.push(
        //   blob2File(compressBase64, _files[0].file.name, _files[0].file.type)
        // );
        // Promise.all(temp)
        //   .then(async (res) => {
        //     const arr = await Promise.all(res.map((t, i) => uploadOss(t, i, t.type.replace('image/', ''))))
        //     changeData([...img, ...arr])
        //   })
        const res = await uploadOss(_files[0].file, 0, _files[0].file.type.replace('image/', ''))
        changeData([...img, res])
      } else {
        Toast.info("只支持上传图片类型文件");
      }
    } else {
      changeData(files);
    }
  };

  // 视频
  const videoChangePicker = async (files) => {
    const _files = files.filter(t => !!t.file)
    // console.log(files)
    if (_files.length > 0) {
      if (_files[0].file.type.indexOf("video") !== -1) {
        const res = await uploadOss(_files[0].file, 0, _files[0].file.type.replace('video/', ''))
        changeData([res])
      } else {
        Toast.info("只支持视频类型文件");
      }
    } else {
      changeData(files);
    }
  }

  const uploadOss = (file, i, ext) => {
    return new Promise((resolve) => {
      const filename = new Date().getTime() + i * 2020;
      const request = new FormData();
      request.append("OSSAccessKeyId", OSSData.accessid);
      request.append("policy", OSSData.policy);
      request.append("Signature", OSSData.signature);
      request.append("key", OSSData.dir + filename + '.' + ext);
      request.append("file", file);
      upload(`https://${OSSData.host}`, request, (res) => {
        if (res.readyState === 4 && res.status >= 200 && res.status < 400) {
          const url = OSSData.ossUrl + OSSData.dir + filename + '.' + ext
          resolve({ url })
        }
      });
    })
  }

  const blob2File = (path, filename, contentType) => {
    return new Promise((resolve) => {
      fetch(path)
        .then((data) => {
          const blob = data.blob();
          return blob;
        })
        .then((blob) => {
          const file = new File([blob], filename, {
            type: contentType,
            lastModified: Date.now(),
          });
          resolve(file);
        });
    });
  };

  const upload = (url, from, callback) => {
    var xhr = new XMLHttpRequest();
    xhr.open("post", url, true);
    xhr.onreadystatechange = () => {
      callback(xhr);
    };
    xhr.send(from);
  };

  const imageClick = (index, fs) => {
    setModal(true);
    setUrl(fs[index].url);
  };

  return (
    <div className="img-wrapper">
      <div className="title">
        {title} {title && !showStart && <i className="red">*</i>}
      </div>
      <div className="img-content">
        {type == 'img' && (<ImagePicker
          files={img}
          disableDelete={disabled}
          onChange={changePicker}
          onImageClick={(index, fs) => imageClick(index, fs)}
          selectable={img.length >= max ? false : true}
        />)}
        {type == 'video' && (<ImagePicker
          files={img}
          accept="video/*"
          disableDelete={disabled}
          onChange={videoChangePicker}
          onImageClick={(index, fs) => imageClick(index, fs)}
          selectable={img.length >= 1 ? false : true}
        />)}
      </div>
      <Modal
        visible={showModal}
        transparent
        maskClosable={false}
        closable={true}
        onClose={() => {
          setModal(false);
        }}
        wrapClassName={"my-modal"}
      >
        <div className="modal-img-wrapper">
          {type == 'img' && (<img style={{ width: "100%", objectFit: "cover" }} src={url} alt="" />)}
          {type == 'video' && (
            <video
              src={url}
              style={{ width: '100%', height: '220px', backgroundColor: '#000' }}
              controls
              x-webkit-airplay="allow"
              x5-video-player-type="h5"
              x5-video-player-fullscreen="false"
              x5-video-orientation="portraint"
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ImgPicker;
