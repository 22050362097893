/*
 * @Author: Fan
 * @Date: 2020-12-01 10:27:52
 * @LastEditors: Fan
 * @LastEditTime: 2020-12-02 16:57:23
 * @Description: file content
 */
import React from "react";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import Login from "@/page/login";
import Index from "@/page/index";
import Store from "@/page/store";
import Resources from "@/page/resources";
import OtherInfo from "@/page/otherInfo";
import Invitation from "@/page/invitation";
import Record from "@/page/record";
import Notice from "@/page/notice";
const setTitle = (title) => {
  document.title = title;
};
const RouterList = [
  { title: "登录", path: "/login", component: Login },
  { title: "注册", path: "/register", component: Login },
  { title: "忘记密码", path: "/forgetpwd", component: Login },
  { title: "协议", path: "/resources", component: Resources },
  { title: "基本信息", path: "/", component: Index, requiresAuth: true },
  { title: "线上开店认证", path: "/store", component: Store, requiresAuth: true },
  { title: "通知", path: "/notice", component: Notice, requiresAuth: true  },
  {
    title: "树懒生活",
    path: "/otherinfo",
    component: OtherInfo,
    requiresAuth: true,
  },
  {
    title: "扫码付款记录",
    path: "/record",
    component: Record,
    requiresAuth: true,
  },
  {
    title: "邀请人数",
    path: "/invitation",
    component: Invitation,
    requiresAuth: true,
  },
];
// 需要token权限的路由
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      setTitle(rest.title);
      return Cookies.get("token") ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);
const RouteItemDom = (item) => {
  return item.requiresAuth ? (
    <PrivateRoute
      exact
      path={item.path}
      key={item.path}
      title={item.title}
      component={item.component}
    />
  ) : (
    <Route
      exact
      path={item.path}
      key={item.path}
      title={item.title}
      render={(props) => {
        setTitle(item.title);
        return <item.component {...props} />;
      }}
    />
  );
};

const BasicRoute = () => (
  <BrowserRouter>
    <Switch>
      {/* exact 严格模式，统一开启 */}
      {RouterList.map((item) => RouteItemDom(item))}
    </Switch>
  </BrowserRouter>
);

export default BasicRoute;
