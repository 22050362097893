import React, {useEffect, useState, useRef} from 'react';
import {Checkbox, DatePicker, List, Modal, Picker, Toast} from 'antd-mobile';
import './index.less';
import Address from "@/components/address"
import SbuBranchAreaPicker from "@/components/sbuBranchAreaPicker"
import ImgPicker from "@/components/imgPicker"
import StepNum from "@/components/step"
import FirstStep from "@/components/firstStep"
import PickerModal from "@/components/pickerModal"
import ToLogin from "@/components/ToLogin/index";
import {handleImg, isCardNo, operateImg} from "@/utils/index"
import {
  isEmail,
  isInputEmailText,
  isInputNumText,
  isInputTelephoneText,
  isPhone,
  isSocialCreditCode
} from "@/utils/regexp";
import {ADD_STORE_BASE, AGENT, IMAGE2TEXT, STORE_AUDIT_INFO, UNIONPAYBANK,CODE} from "@/graphql/store";
import client from "@/graphql/apollo-config";
import {dateFormat,isEmpty} from "../../utils";
import moment from "moment";

const axios = require('axios').default;
const AgreeItem = Checkbox.AgreeItem;
const fmt = 'YYYY-MM-DD'
const maxDate = new Date(2099, 11, 30, 23, 59, 59);

let category_code = null;

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(()=>{
    savedCallback.current = callback;
  },[callback])

  useEffect(()=>{
    function tick() {
      savedCallback.current();
    }
    if(delay !== null){
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  },[delay])
}

const Index = (props) => {
  const [storeIndustry, setStoreIndustry] = useState([]); // 行业归属
  const [storeCategroy, setStoreCategroy] = useState([]) // 商家类目
  const [OSSData, setOSSData] = useState({})
  const [first, setFirst] = useState(true) // 是否首次
  const [isFirstBase, setFirstBase] = useState(true) // 是否首次
  const [step, setStep] = useState(0) // 步骤
  const [showModal, setModal] = useState(false)
  const [modalImg, setModalImg] = useState(1)
  const [longCredit, setLongCredit] = useState(false)  // 营业执照长期
  const [longIdCard, setIdCard] = useState(false)  // 身份证长期
  const [longIdCard2, setIdCard2] = useState(false); // 受益人身份证长期
  const [regin, setRegin] = useState([{'label': ''}, {'label': ''}, {'label': ''}])
  const [unionpayBank, setUnionpayBank] = useState([])
  const [unionpayBankCategories, setUnionpayBankCategories] = useState([])
  const [modalPicker, setModalPicker] = useState({
    show: false,
    title: '',
    arr:[],
    code:'',
    name:''
  }) // 显示弹窗
  const [same,setSame] = useState(false) // 联系人与法人信息一致
  const [interval, setInterval] = useState(null);

  const [storeInfo, setStoreInfo] = useState({
    settle_status: null, // 入驻方式
    name: '', // 店铺名称
    short_name: '', // 店铺简称
    store_category_id: '', // 商家类目
    store_category_name: '',
    product_type: 0, // 店铺类型
    average_spend: '', // 人均消费
    lowest_delivery_price: '', // 起送价
    ali_account: '', // 支付宝账号
    province: '',
    city: '',
    area: '',
    lng: '',
    lat: '',
    code: '', // 推荐代理
    address: '', // 店铺地址
    hot_line: '', // 客服手机
    mcc_code: '', // 经营类别
    mcc_name: '',
    industry_code: '', // 行业类目
    industry_name: '',
    credit_code: '', // 营业执照号
    corporation: '', // 法人姓名
    id_card_no: '', // 法人身份证号
    id_card_address: '', // 法人身份证地址
    contact_name: '', // 受益人姓名
    contact_id_card: '', // 受益人身份证
    contact_mobile: '', // 受益人电话
    contact_email: '', // 受益人邮箱
    contact_card_front: [], // 受益人身份证正面
    contact_card_back: [], // 受益人身份证背面
    contact_card_address: null, // 受益人身份证地址
    contact_card_valid_start: null, // 受益人身份证有效期开始
    contact_card_valid_end: null,  // 受益人身份证有效期结束
    bank_account_name: '', // 开户名称
    bank_account_type: '', //账户类型
    bank_account: '', // 银行卡号
    bank_name: '', // 银行名称
    bank_branch: '', // 支行名称
    bank_code: '', // 银行联行号
    bank_province: '', // 支行省
    bank_city: '', // 支行市
    bank_area: '', // 支行区
    business_license: [], // 营业执照
    house_num: [], // 门头照
    cover: [], // 店铺头像
    images: [], // 店铺轮播图
    video: [], // 介绍视频
    notice: '',
    checkout_counter: [],// 收银台
    store_inner: [],// 店内照
    id_card_front: [],// 身份证正面
    id_card_back: [],// 身份证背面
    id_card_hand: [],// 手持身份证
    bank_id_card_front: [],// 开户人身份证正面照
    bank_id_card_back: [],// 身份证背面照
    bank_hand_image: [],// 手持银行卡照
    opening_permit_image: [], // 开户许可证
    company_name: '', // 公司名称
    business_license_valid_start: '', // 营业执照开始时间
    business_license_valid_end: '', // 营业执照结束时间
    id_card_valid_start: '', //身份证开始时间
    id_card_valid_end: '', // 身份证结束时间
    mobile: '', // 法人手机号
    bank_account_id_card_no: '',// 开户人证件号码
    bank_card_image: [], // 结算银行卡照
    registered_capital: '', // 注册资本
    email: '', // 邮箱
    bank_mobile: '', // 银行卡预留手机号
    bf_industry_code: '', // 行业归属
  });

  // 审核状态
  const getStatus = () => {
    switch (storeBase.status) {
      case 0:
        return '审核中'
      case 1:
        return '审核通过'
      case 2:
        return '审核不通过'
      case 3:
        return '审核中'
      case 4:
        return '待银行审核'
      case 5:
        return '审核结果：资料未完善，请在补充资料提交打款验证！'
      case 6:
        return '审核结果：资料未完善，请在补充资料提交商户授权认证！'
      case 7:
        return '待银行审核'
    }
  }
  const [storeBase, setStoreBase] = useState({
    reject_reason: null,
    status: null,
    reject_type: null
  });
  const [infoStatus, setInfoStatus] = useState({
    status: null,
    reject_reason: null,
    auth_image: null
  })

  useEffect(() => {
    if (props.location.search.slice(6)) {
      changeData("settle_status", props.location.search.slice(6))
    }
    getStoreInfo();
  }, []);

  useEffect(() => {
    setAddress(regin[0].label, regin[1].label, regin[2].label, false);
    querySbuBranch();
  }, [regin])

  useInterval(() => {
    localStorage.setItem('store',JSON.stringify(storeInfo));
  }, interval);

  const bankType = [{
    label: '对公',
    value: 0,
  }, {
    label: '对私',
    value: 1
  }]

  const history = props.history

  const rule1 = [
    {
      rule: !storeInfo.short_name || !storeInfo.short_name.trim(),
      title: "请填写店铺简称"
    },
    {
      rule: !storeInfo.store_category_id,
      title: "请选择店铺所属类目"
    },
    {
      rule: !storeInfo.average_spend,
      title: "请填写人均消费",
    },
    {
      rule: (storeInfo.average_spend + '').indexOf('.') != -1,
      title: "人均消费不能为小数",
    },
    {
      rule: !storeInfo.mcc_code,
      title: "请选择经营类别"
    },
    {
      rule: !storeInfo.bf_industry_code,
      title: "请选择所属行业",
    },
    {
      rule: !storeInfo.industry_code,
      title: "请选择行业类目"
    },
    {
      rule: !storeInfo.province,
      title: "请选择省份"
    },
    {
      rule: !storeInfo.city,
      title: "请选择市"
    },
    {
      rule: !storeInfo.area,
      title: "请选择区/县"
    },
    {
      rule: !storeInfo.address,
      title: "请填写详细地址"
    },
    {
      rule: storeInfo.address && storeInfo.address.length < 5,
      title: "请填写5个字以上的详细地址"
    },
    {
      rule: !storeInfo.hot_line,
      title: "请填写客服手机"
    },
    {
      rule: storeInfo.hot_line && (storeInfo.hot_line.length != 11 || storeInfo.hot_line[0] != 1),
      title: "客服手机号码格式不正确"
    },
    {
      rule: !storeInfo.ali_account,
      title: "请填写支付宝账号",
    },
    {
      rule: storeInfo.code && (storeInfo.code.length != 11 || storeInfo.code[0] != 1),
      title: "代理手机号码格式不正确"
    },
    {
      rule: storeInfo.settle_status !== 1 && JSON.stringify(storeInfo.business_license) == '[]',
      title: "请上传营业执照"
    },
    {
      rule: storeInfo.settle_status !== 1 && !storeInfo.company_name,
      title: "请填写营业执照一致的名称"
    },
    {
      rule: storeInfo.settle_status !== 1 && !storeInfo.credit_code,
      title: "请填写统一社会信用代码"
    },
    {
      rule: storeInfo.settle_status !== 1 && storeInfo.credit_code && storeInfo.credit_code.length < 18,
      title: "统一社会信用代码必须18位"
    },
    {
      rule: storeInfo.settle_status !== 1 && storeInfo.credit_code && !isSocialCreditCode(storeInfo.credit_code),
      title: "统一社会信用代码不正确"
    },
    {
      rule: !longCredit && storeInfo.settle_status !== 1 && !storeInfo.business_license_valid_start,
      title: "请选择营业执照开始时间"
    },
    {
      rule: !longCredit && storeInfo.settle_status !== 1 && !storeInfo.business_license_valid_end,
      title: "请选择营业执照结束时间"
    },
    {
      rule: storeInfo.settle_status !== 1 && !storeInfo.registered_capital,
      title: "请填写注册资本"
    },
    {
      rule: storeInfo.house_num && JSON.stringify(storeInfo.house_num) == '[]',
      title: "请上传门头照"
    },
    {
      rule: storeInfo.cover && JSON.stringify(storeInfo.cover) == "[]",
      title: "请上传店铺头像",
    },
    {
      rule: storeInfo.settle_status !== 1 && JSON.stringify(storeInfo.checkout_counter) == '[]',
      title: "请上传收银台"
    },
    {
      rule: JSON.stringify(storeInfo.store_inner) == '[]',
      title: "请上传店内照"
    },
  ]

  const rule2 = [
    {
      rule: storeInfo.id_card_front && JSON.stringify(storeInfo.id_card_front) == '[]',
      title: "请上传身份证正面照"
    },
    {
      rule: storeInfo.id_card_back && JSON.stringify(storeInfo.id_card_back) == '[]',
      title: "请上传身份证背面照"
    },
    // {
    //   rule: storeInfo.id_card_hand && JSON.stringify(storeInfo.id_card_hand) == '[]',
    //   title: "请上传手持身份证照"
    // },
    {
      rule: !storeInfo.corporation || !storeInfo.corporation.trim(),
      title: "请填写法人姓名"
    },
    {
      rule: !storeInfo.id_card_no,
      title: "请填写法人身份证号"
    },
    {
      rule: !isCardNo(storeInfo.id_card_no),
      title: "请填写正确的法人身份证号"
    },
    {
      rule: !storeInfo.id_card_address,
      title: "请填写法人身份证地址",
    },
    {
      rule: !storeInfo.id_card_valid_start,
      title: "请选择身份证有效期开始时间"
    },
    {
      rule: !storeInfo.id_card_valid_end,
      title: "请选择身份证有效期结束时间"
    },
    {
      rule: !storeInfo.mobile,
      title: "请填写法人手机号"
    },
    {
      rule: !isPhone(storeInfo.mobile),
      title: "法人手机号码格式不正确"
    },
    {
      rule: storeInfo.settle_status !== 1 && !storeInfo.email,
      title: "请填写邮箱"
    },
    {
      rule: storeInfo.settle_status !== 1 && !isEmail(storeInfo.email),
      title: "邮箱格式不正确"
    },
    {
      rule: storeInfo.settle_status == 2 && storeInfo.contact_card_front && JSON.stringify(storeInfo.contact_card_front) == '[]',
      title: "请上传受益人身份证正面照",
    },
    {
      rule: storeInfo.settle_status == 2 && storeInfo.contact_card_back && JSON.stringify(storeInfo.contact_card_back) == '[]',
      title: "请上传受益人身份证背面照",
    },
    {
      rule: storeInfo.settle_status == 2 && (!storeInfo.contact_name || !storeInfo.contact_name.trim()),
      title: "请填写受益人姓名",
    },
    {
      rule: storeInfo.settle_status == 2 && !storeInfo.contact_id_card,
      title: "请填写受益人身份证号",
    },
    {
      rule: storeInfo.settle_status == 2 && !isCardNo(storeInfo.contact_id_card),
      title: "请填写正确的受益人身份证号",
    },
    {
      rule: storeInfo.settle_status == 2 && !storeInfo.contact_card_address,
      title: "请填写受益人身份证地址",
    },
    {
      rule: storeInfo.settle_status == 2 && !longIdCard2 && !storeInfo.contact_card_valid_start,
      title: "请选择受益人身份证有效期开始时间",
    },
    {
      rule: storeInfo.settle_status == 2 && !longIdCard2 && !storeInfo.contact_card_valid_end,
      title: "请选择受益人身份证有效期结束时间",
    },
    {
      rule: storeInfo.settle_status == 2 && !storeInfo.contact_mobile,
      title: "请填写受益人手机号码或电话号码",
    },
    {
      rule: storeInfo.settle_status == 2 && !storeInfo.contact_email,
      title: "请填写受益人邮箱",
    },
    {
      rule: storeInfo.settle_status == 2 && storeInfo.contact_email && !isEmail(storeInfo.contact_email),
      title: "受益人邮箱格式不正确",
    },
  ]

  const rule3 = [
    {
      rule: storeInfo.bank_id_card_front && JSON.stringify(storeInfo.bank_id_card_front) == '[]',
      title: "请上传开户人身份证正面照"
    },
    {
      rule: storeInfo.bank_id_card_back && JSON.stringify(storeInfo.bank_id_card_back) == '[]',
      title: "请上传开户人身份证背面照"
    },
    {
      rule: storeInfo.settle_status !== 2 && storeInfo.bank_card_image && JSON.stringify(storeInfo.bank_card_image) == '[]',
      title: "请上传结算银行卡照"
    },
    // {
    //   rule: storeInfo.settle_status != 2 && JSON.stringify(storeInfo.bank_hand_image) == '[]',
    //   title: "请上传手持银行卡照"
    // },
    {
      rule: storeInfo.settle_status == 2 && JSON.stringify(storeInfo.opening_permit_image) == '[]',
      title: "请上传开户许可证"
    },
    {
      rule: !storeInfo.bank_account,
      title: "请填写银行卡号"
    },
    {
      rule: storeInfo.bank_account && (storeInfo.bank_account.length < 9 || storeInfo.bank_account.length > 30),
      title: "银行卡号限制9-30位"
    },
    {
      rule: !storeInfo.bank_name,
      title: "请选择银行名称"
    },
    {
      rule: !storeInfo.bank_province,
      title: "请选择支行所在省份"
    },
    {
      rule: !storeInfo.bank_city,
      title: "请选择支行所在市"
    },
    {
      rule: !storeInfo.bank_branch,
      title: "请选择支行名称"
    },
    {
      rule: !storeInfo.bank_account_name || !storeInfo.bank_account_name.trim(),
      title: "请填写开户名称"
    },
    {
      rule: !storeInfo.bank_account_id_card_no,
      title: "请填写开户人证件号码"
    },
    {
      rule: !isCardNo(storeInfo.bank_account_id_card_no),
      title: "请填写正确的开户人证件号码"
    },
    {
      rule: storeInfo.settle_status == 1 && !storeInfo.bank_mobile,
      title: "请填写银行卡预留手机号"
    },
    {
      rule: storeInfo.settle_status == 1 && !isPhone(storeInfo.bank_mobile),
      title: "预留手机号格式不正确"
    }
  ]


  const getStoreInfo = async () => {
    try {
      const {data} = await client.query({
        query: STORE_AUDIT_INFO,
        fetchPolicy: "no-cache"
      });
      if (data) {
        setUnionpayBankCategories(data.unionpayBankCategories);
        setOSSData({
          ...data.oss
        })
        // 处理商品类目
        let storeCategory = [];
        data.storeCategory.map((value) => {
          storeCategory.push({'value': value.id, 'label': value.name})
        })
        setStoreCategroy(storeCategory)
        setStoreIndustry(data.storeIndustry.map(t => ({ value: t.code, label: t.name })))

        if (data.storeInfo) {
          const user = data.user;
          const storeData = data.storeInfo
          let a = null
          let b = null
          // 没有审核信息拿cookie里面,cookie返回的是数组，后台返回的是对象，所以要分开处理
          if(!storeData.store_base_audit){
            a = localStorage.getItem("store") != null?JSON.parse(localStorage.getItem("store")):storeInfo
            b = {
              code:a.code==''?user.parent_mobile:a.code,
              business_license: imgData(a.business_license.length >0?a.business_license[0].url:'', 'business_license'),
              house_num: imgData(a.house_num.length >0?a.house_num[0].url:'', 'house_num'),
              cover: imgData(a.cover.length > 0 ? a.cover[0].url : '', "cover"),
              images: a.images || [],
              video: imgData(a.video.length > 0 ? a.video[0].url : '', "video"),
              checkout_counter: imgData(a.checkout_counter.length >0?a.checkout_counter[0].url:'', 'checkout_counter'),
              store_inner: imgData(a.store_inner.length >0?a.store_inner[0].url:'', 'store_inner'),
              id_card_front: imgData(a.id_card_front.length >0?a.id_card_front[0].url:'', 'id_card_front'),
              id_card_back: imgData(a.id_card_back.length >0?a.id_card_back[0].url:'', 'id_card_back'),
              id_card_hand: imgData(a.id_card_hand.length >0?a.id_card_hand[0].url:'', 'id_card_hand'),
              bank_id_card_front: imgData(a.bank_id_card_front.length >0?a.bank_id_card_front[0].url:'', 'bank_id_card_front'),
              bank_id_card_back: imgData(a.bank_id_card_back.length >0?a.bank_id_card_back[0].url:'', 'bank_id_card_back'),
              bank_hand_image: imgData(a.bank_hand_image.length >0?a.bank_hand_image[0].url:'', 'bank_hand_image'),
              opening_permit_image: imgData(a.opening_permit_image.length >0?a.opening_permit_image[0].url:'', 'opening_permit_image'),
              bank_card_image: imgData(a.bank_card_image.length >0?a.bank_card_image[0].url:'', 'bank_card_image'),

              contact_card_front: imgData(a.contact_card_front && a.contact_card_front.length > 0 ? a.contact_card_front[0].url : '', 'contact_card_front'),
              contact_card_back: imgData(a.contact_card_back && a.contact_card_back.length > 0 ? a.contact_card_back[0].url : '', 'contact_card_back'),
            }
            setInterval(5000)
          }else{
            // 首次就拿审核信息里面的，不是首次就拿门店信息
            a = storeData.store_base_audit.is_first_audit ? storeData.store_base_audit : storeData
            b = {
              code:a.code==''?user.parent_mobile:a.code,
              business_license: imgData(a.business_license && a.business_license.url, 'business_license'),
              house_num: imgData(a.house_num && a.house_num.url, 'house_num'),
              cover: imgData(a.cover && a.cover.url, "cover"),
              images: a.images || [],
              video: imgData(a.video && a.video.url, "video"),
              checkout_counter: imgData(a.checkout_counter && a.checkout_counter.url, 'checkout_counter'),
              store_inner: imgData(a.store_inner && a.store_inner.url, 'store_inner'),
              id_card_front: imgData(a.id_card_front && a.id_card_front.url, 'id_card_front'),
              id_card_back: imgData(a.id_card_back && a.id_card_back.url, 'id_card_back'),
              id_card_hand: imgData(a.id_card_hand && a.id_card_hand.url, 'id_card_hand'),
              bank_id_card_front: imgData(a.bank_id_card_front && a.bank_id_card_front.url, 'bank_id_card_front'),
              bank_id_card_back: imgData(a.bank_id_card_back && a.bank_id_card_back.url, 'bank_id_card_back'),
              bank_hand_image: imgData(a.bank_hand_image && a.bank_hand_image.url, 'bank_hand_image'),
              opening_permit_image: imgData(a.opening_permit_image && a.opening_permit_image.url, 'opening_permit_image'),
              bank_card_image: imgData(a.bank_card_image && a.bank_card_image.url, 'bank_card_image'),

              contact_card_front: imgData(a.contact_card_front && a.contact_card_front.url, 'contact_card_front'),
              contact_card_back: imgData(a.contact_card_back && a.contact_card_back.url, 'contact_card_back'),
              contact_card_valid_start: a.contact_card_valid_start != '0000-00-00' ? a.contact_card_valid_start : null,
              contact_card_valid_end: a.contact_card_valid_end != '0000-00-00' ? a.contact_card_valid_end : null,

              email: a.email || '',
              bank_mobile: a.bank_mobile || '',
              registered_capital: a.registered_capital || '',
            }
          }
          if ((a.business_license_valid_end && a.business_license_valid_end.indexOf('2099') != -1) || a.settle_status == 0) {
            setLongCredit(true)
          }
          if (a.id_card_valid_end && a.id_card_valid_end.indexOf('2099') != -1) {
            setIdCard(true)
          }
          if (a.contact_card_valid_end && a.contact_card_valid_end.indexOf("2099") != -1) {
            setIdCard2(true);
          }
          setStoreInfo({
            ...a,
            ...b,
          })
          // 小于5代表没到补充资料那步，状态等于1是审核通过了，id存在是代表店铺成功开启
          const isFirst = (storeData.store_base_audit.status < 5 && storeData.store_base_audit.status != 1 && !storeData.id) || storeData.short_name == ''
          const isFirstBase = storeData.store_base_audit.status < 5 && storeData.store_base_audit.status != 1 && !storeData.id
          setFirst(isFirst)
          setFirstBase(isFirstBase)
          setStoreBase({
            reject_reason: data.storeInfo.store_base_audit.reject_reason,
            status: data.storeInfo.store_base_audit.status,
            reject_type: data.storeInfo.store_base_audit.reject_type
          })
          setInfoStatus({
            status: data.additionalInfo.status,
            reject_reason: data.additionalInfo.reject_reason,
            auth_image: data.additionalInfo.auth_image && data.additionalInfo.auth_image.url
          })
          if (data.unionpayBankCategories) {
            const bankCategory = data.unionpayBankCategories.find((i) => {
              return i.label == storeData.store_base_audit.bank_name;
            });
            if (bankCategory && bankCategory.value) {
              category_code = bankCategory.value;
              setRegin([
                {'label': storeData.store_base_audit.bank_province},
                {'label': storeData.store_base_audit.bank_city},
                {
                  'label': storeData.store_base_audit.bank_area,
                  'code': storeData.store_base_audit.bank_area_code
                }]);
            }
          }
        }
      }
    } catch (error) {}
  }


  const closeModal = (v, name) =>{
    if (v && name){
      setStoreInfo({
        ...storeInfo,
        [modalPicker.code]: v,
        [modalPicker.name]: name
      })
    }
    setModalPicker({
      ...modalPicker,
      show: false
    })
  }

  const querySbuBranch = async () => {
    console.log(regin,category_code , 9999)
    if (regin && regin[2].code && category_code) {
      try {
        const {data} = await client.query({
          query: UNIONPAYBANK,
          variables: {
            city_code: regin[2].code,
            category_code: category_code
          },
          fetchPolicy: "no-cache"
        });
        if (data) {
          console.log('unionpayBank = ', data)
          const {unionpayBank} = data;
          let sbuBank = [];
          unionpayBank.map((i) => {
            sbuBank.push({'id': i.id, 'value': i.code, 'label': i.name, 'city_code': i.city_code});
          })
          setUnionpayBank(sbuBank);
        }
      } catch (error) {
      }
    }
  }

  const imgData = (url, id) => {
    if (url) {
      let c = {}
      c.url = url
      c.id = id
      return [c]
    } else {
      return []
    }
  }

  const setStoreInfoes = (name, value) => {
    console.log(name, value)
    setStoreInfo({
      ...storeInfo,
      [name]: value
    })
  }

  const chooseSettle = (i) => {
    setLongCredit(i == 0?true:false)
    setStoreInfo({
      ...storeInfo,
      settle_status: i,
      mcc_code: '',
      mcc_name: '',
      industry_code: '',
      industry_name: '',
      bank_account_type: i == 2 ? 0 : null,
      business_license_valid_start: i == 0?'2099-12-30':'',
      business_license_valid_end: i == 0?'2099-12-30':'',
    })
  }

  // 修改数据
  const changeData = (name, value) => {
    switch (name) {
      case "hot_line":
        if (isInputTelephoneText(value)) {
          setStoreInfoes(name, value);
        }
        break;
      case "contact_mobile":
        if (isInputTelephoneText(value)) {
          setStoreInfoes(name, value);
        }
        break;
      case "contact_email":
        if (isInputEmailText(value)) {
          setStoreInfoes(name, value);
        }
        break;
      case "bank_account":
        if (isInputNumText(value)) {
          setStoreInfoes(name, value);
        }
        break;
      default:
        setStoreInfoes(name, value);
        break
    }
  }

  const showExample = (img) => {
    setModal(true)
    setModalImg(img)
  }

  // 修改地址
  const setAddress = (province, city, area, type) => {
    if (type) {
      //设置地址
      setStoreInfo({
        ...storeInfo,
        province: province,
        city: city,
        area: area
      })
    } else {
      //银行卡省市
      setStoreInfo({
        ...storeInfo,
        bank_province: province,
        bank_city: city,
        bank_area: area,
        // bank_branch: ''
      })
    }
  }

  const changeBank = (v) => {
    if (unionpayBankCategories) {
      const bank = unionpayBankCategories.filter((i) => {
        return i.value == v;
      });
      if (bank.length == 1) {
        category_code = bank[0].value;
        setStoreInfo({
          ...storeInfo,
          bank_name: bank[0].label,
          bank_branch: ''
        })
        querySbuBranch();
      } else {
        category_code = null;
        setStoreInfo({
          ...storeInfo,
          bank_name: bank[0].label,
          bank_branch: ''
        })
      }
    }
  }
  const submitMutate = async (lng, lat) => {
    try {
      const {data} = await client.mutate({
        mutation: ADD_STORE_BASE,
        variables: {
          // name: storeInfo.short_name,
          short_name: storeInfo.short_name,
          province: storeInfo.province,
          city: storeInfo.city,
          area: storeInfo.area,
          address: storeInfo.address,
          lng: lng,
          lat: lat,
          code: storeInfo.code,
          mcc_code: storeInfo.mcc_code,
          industry_code: storeInfo.industry_code,
          store_category_id: storeInfo.store_category_id,
          product_type: storeInfo.product_type,
          average_spend: storeInfo.average_spend,
          lowest_delivery_price: storeInfo.lowest_delivery_price || 0,
          ali_account: storeInfo.ali_account,
          settle_status: storeInfo.settle_status,
          hot_line: storeInfo.hot_line,
          credit_code: storeInfo.credit_code,
          business_license: handleImg(storeInfo.business_license),
          house_num: handleImg(storeInfo.house_num),
          cover: handleImg(storeInfo.cover),
          images: handleImg(storeInfo.images) || [],
          video: handleImg(storeInfo.video),
          notice: storeInfo.notice || '',
          checkout_counter: handleImg(storeInfo.checkout_counter),
          store_inner: handleImg(storeInfo.store_inner),
          corporation: storeInfo.corporation,
          id_card_no: storeInfo.id_card_no,
          id_card_address: storeInfo.id_card_address,
          id_card_front: handleImg(storeInfo.id_card_front),
          id_card_back: handleImg(storeInfo.id_card_back),
          id_card_hand: handleImg(storeInfo.id_card_hand),
          contact_name: storeInfo.contact_name,
          contact_id_card: storeInfo.contact_id_card,
          contact_mobile: storeInfo.contact_mobile,
          contact_email: storeInfo.contact_email,
          contact_card_front: handleImg(storeInfo.contact_card_front),
          contact_card_back: handleImg(storeInfo.contact_card_back),
          contact_card_address: storeInfo.contact_card_address || '',
          contact_card_valid_start: storeInfo.contact_card_valid_start || '',
          contact_card_valid_end: storeInfo.contact_card_valid_end || '',
          bank_account_name: storeInfo.bank_account_name,
          bank_account_type: storeInfo.settle_status == 2 ? 0 : 1,
          bank_account: storeInfo.bank_account,
          bank_name: storeInfo.bank_name,
          bank_branch: storeInfo.bank_branch,
          bank_code: storeInfo.bank_code,
          bank_province: storeInfo.bank_province,
          bank_city: storeInfo.bank_city,
          bank_area: storeInfo.bank_area,
          bank_id_card_front: handleImg(storeInfo.bank_id_card_front),
          bank_id_card_back: handleImg(storeInfo.bank_id_card_back),
          bank_hand_image: handleImg(storeInfo.bank_hand_image),
          opening_permit_image: handleImg(storeInfo.opening_permit_image),
          company_name: storeInfo.company_name,
          business_license_valid_start: storeInfo.business_license_valid_start,
          business_license_valid_end: storeInfo.business_license_valid_end,
          id_card_valid_start: storeInfo.id_card_valid_start,
          id_card_valid_end: storeInfo.id_card_valid_end,
          mobile: storeInfo.mobile,
          bank_account_id_card_no: storeInfo.bank_account_id_card_no,
          bank_card_image: handleImg(storeInfo.bank_card_image),
          email: storeInfo.email,
          bank_mobile: storeInfo.bank_mobile || '',
          registered_capital: storeInfo.registered_capital || 0,
          bf_industry_code: storeInfo.bf_industry_code,
        },
        fetchPolicy: "no-cache"
      });
      console.log(data, 9898989)
      if (data.addStoreBase) {
        Toast.info('提交成功,请等待审核')
        setTimeout(() => {
          window.location.href = '/'
        }, 1000)
      }
    } catch (error) {
    }
  }

  // 显示补充资料弹窗
  const moreData = async (status) => {
    history.push(`otherInfo?type=${storeInfo.settle_status}&status=${status}`)
  }

  // 店铺类目
  const changeCategroy = (value) => {
    const a = storeCategroy.filter((i) => {
      return i.value == value
    })
    setStoreInfo({
      ...storeInfo,
      store_category_name: a[0].label,
      store_category_id: a[0].value
    })
  }

  // 通过地址获取经纬度
  const submit = async () => {
    if (step == 0) {
      if (JSON.stringify(storeInfo.settle_status) == 'null') {
        Toast.info('请选择入驻方式')
      } else {
        setStep(1)
      }
    }
    if (step == 1 && verification(rule1)) {
      setStep(2)
    }
    if (step == 2 && verification(rule2)) {
      setStep(3)
    }
    if (step == 3 && verification(rule3)) {
      if (!first){
        Toast.info('不允许再次提交')
        return
      }
      // 0典栈待审核，3蜂鸟待审核，4带银行审核，0未驳回
      if (storeBase.status == 0 || storeBase.status == 3 || storeBase.status == 4 || storeBase.reject_type == 0 || storeBase.status == 7) {
        Toast.info('正在审核中，请勿重复提交')
      } else {
        //银行卡是否有效
        // const {data} = await axios.get(`https://ccdcapi.alipay.com/validateAndCacheCardInfo.json?cardNo=${storeInfo.bank_account}&cardBinCheck=true`);
        // console.log('checkData = ', data)
        // if (data && data.validated) {
          const {data} = await axios.get(`https://restapi.amap.com/v3/geocode/geo?key=75060fae7d3d453c790b609a312a7a08&address=${storeInfo.city + storeInfo.area + storeInfo.address}&city=${storeInfo.city}`)
          const d = data.geocodes[0].location.indexOf(",")
          const lng = data.geocodes[0].location.slice(0, d)
          const lat = data.geocodes[0].location.slice(d + 1)
          submitMutate(lng, lat)
        // } else {
        //   Toast.info('请填写有的效银行卡号')
        // }
      }
    }
  }

  const verification = (rule) => {
    if (!first) {
      return true
    } else {
      for (let item of rule) {
        if (item.rule) {
          Toast.info(item.title)
          return false
        }
      }
      return true
    }
  }

  const getCode = async (type) => {
    try {
      const {data} = await client.query({
        query: CODE,
        variables:{
          type: type,
          settle_status: storeInfo.settle_status
        },
        fetchPolicy: "no-cache"
      });
      if (data) {
        let a = [];
        data.code.map((i) => {
          a.push({'id': i.code, 'value': i.code, 'label': i.name});
        })
        if(type == 1){
          setModalPicker({
            show: true,
            title: '请选择经营类别',
            arr: a,
            code: 'mcc_code',
            name: 'mcc_name'
          })
        }else{
          setModalPicker({
            show: true,
            title: '请选择行业类目',
            arr: a,
            code: 'industry_code',
            name: 'industry_name'
          })
        }
      }
    } catch (error) {}
  }

  const queryAgent = async (e) => {
    if (e) {
      try {
        const {data} = await client.query({
          query: AGENT,
          variables: {
            agent_mobile: e
          },
          fetchPolicy: "no-cache"
        });
        if (!data.agent) {
          Toast.info('没找到该代理手机号')
          changeData("code", '')
        }
      } catch (error) {
      }
    }
  }
  const changeBox = (e, type, start, end) => {
    if (type == 1) {
      setLongCredit(e.target.checked)
    } else {
      if (start == 'contact_card_valid_start') {
        setIdCard2(e.target.checked)
      } else {
        setIdCard(e.target.checked)
      }
    }

    if (e.target.checked) {
      setStoreInfo({
        ...storeInfo,
        [start]: '2099-12-30',
        [end]: '2099-12-30',
      })
    } else {
      setStoreInfo({
        ...storeInfo,
        [start]: '',
        [end]: '',
      })
    }
  }

  const changeSame = (e)=>{
      if(storeInfo.corporation && storeInfo.id_card_no && storeInfo.mobile){
          const a = e.target.checked
        setSame(a)
        setStoreInfo({
          ...storeInfo,
          contact_name: a?storeInfo.corporation:'',
          contact_id_card: a?storeInfo.id_card_no:'',
          contact_mobile: a?storeInfo.mobile:'',
          contact_card_front: a ? storeInfo.id_card_front.map(t => ({...t})) : [],
          contact_card_back: a ? storeInfo.id_card_back.map(t => ({...t})) : [],
          contact_card_address: a ? storeInfo.id_card_address : '',
          contact_card_valid_start: a ? storeInfo.id_card_valid_start : null,
          contact_card_valid_end: a ? storeInfo.id_card_valid_end : null,
        })
        setIdCard2(a ? longIdCard : false)
      }else{
        Toast.info('请先填写完整的法人信息')
      }

  }
  const getImageType = (name) => {
    switch (name) {
      case 'id_card_front':
      case 'id_card_back':
      case "contact_card_front":
      case "contact_card_back":
      case 'bank_id_card_front':
        return 0;//身份证
      case 'bank_card_image':
        return 1;//银行卡
      case 'business_license':
        return 2;//营业执照
    }
  }
  const image2Text = async (name, arr) => {
    try {
      if (arr && arr.length > 0) {
        const {data} = await client.query({
          query: IMAGE2TEXT,
          variables: {
            type: getImageType(name),
            url: handleImg(arr),
            side: name.indexOf('_front') != -1 ? 'front' : name.indexOf('_back') != -1 ? 'back' : ''
          },
          fetchPolicy: "no-cache"
        });
        console.log('图片识别', data);
        if (data && data.image2Text) {
          switch (name) {
            case 'business_license'://营业执照
              const company_name = data.image2Text.company_name;
              const credit_code = data.image2Text.credit_code;
              const start = data.image2Text.business_license_valid_start;
              const end = data.image2Text.business_license_valid_end;
              const startText = storeInfo.settle_status == 0 ?'2099-12-30':!isEmpty(start)&& start!='无' ?moment(start, 'YYYY年MM月DD日').format('YYYY-MM-DD'):'';
              const endText = storeInfo.settle_status == 0 ?'2099-12-30':!isEmpty(end)&& end!='无' ?moment(end, 'YYYY年MM月DD日').format('YYYY-MM-DD'):'';
              setStoreInfo({
                ...storeInfo,
                business_license: arr,
                company_name:!isEmpty(company_name)&& company_name!='无'? company_name:'',
                credit_code: !isEmpty(credit_code)&& credit_code!='无'? credit_code:'',
                business_license_valid_start: startText,
                business_license_valid_end: endText
              });
              // 个体户不用识别
              if (storeInfo.settle_status !== 0){
                setLongCredit(false);
              }
              break;
            case 'id_card_front'://step==2身份证正面照
              const corporation = data.image2Text.name;
              const id_card_no = data.image2Text.id_card_no;
              setStoreInfo({
                ...storeInfo,
                id_card_front: arr,
                corporation: !isEmpty(corporation)&& corporation!='无'? corporation:'',
                id_card_address: data.image2Text.id_card_address,
                id_card_no: !isEmpty(id_card_no)&& id_card_no!='无'? id_card_no:''
              });
              break;
            case 'id_card_back'://step==2身份证背面照
              const idStart = data.image2Text.id_card_valid_start;
              const idEnd = data.image2Text.id_card_valid_end;
              setStoreInfo({
                ...storeInfo,
                id_card_back: arr,
                id_card_valid_start:!isEmpty(idStart)&& idStart!='无' ?moment(idStart, 'YYYYMMDD').format('YYYY-MM-DD'):'',
                id_card_valid_end:!isEmpty(idEnd)&& idEnd!='无' ?moment(idEnd, 'YYYYMMDD').format('YYYY-MM-DD'):''
              });
              setIdCard(false);
              break;
            case "contact_card_front": //step==2 受益人身份证正面照
              const _name = data.image2Text.name;
              const id_card_no2 = data.image2Text.id_card_no;
              setStoreInfo({
                ...storeInfo,
                contact_card_front: arr,
                contact_name: !isEmpty(_name) && _name != "无" ? _name : "",
                contact_card_address: data.image2Text.id_card_address,
                contact_id_card: !isEmpty(id_card_no2) && id_card_no2 != "无" ? id_card_no2 : "",
              });
              break;
            case "contact_card_back": //step==2 受益人身份证背面照
              const idStart2 = data.image2Text.id_card_valid_start;
              const idEnd2 = data.image2Text.id_card_valid_end;
              setStoreInfo({
                ...storeInfo,
                contact_card_back: arr,
                contact_card_valid_start:
                  !isEmpty(idStart2) && idStart2 != "无"
                    ? moment(idStart2, "YYYYMMDD").format("YYYY-MM-DD")
                    : "",
                contact_card_valid_end:
                  !isEmpty(idEnd2) && idEnd2 != "无"
                    ? idEnd2 == "长期"
                      ? "2099-12-30"
                      : moment(idEnd2, "YYYYMMDD").format("YYYY-MM-DD")
                    : "",
              });
              setIdCard2(false);
              break;
            case 'bank_id_card_front'://step==3开户人身份证正面照
              const baicn = data.image2Text.id_card_no;
              setStoreInfo({
                ...storeInfo,
                bank_id_card_front: arr,
                bank_account_id_card_no: !isEmpty(baicn)&& baicn!='无'? baicn:''
              });
              break;
            case 'bank_card_image'://step==3结算银行卡照
              const bank_name = data.image2Text.bank_name;
              var bank = null;
              if (bank_name && unionpayBankCategories) {
                bank = unionpayBankCategories.find((i) => {
                  return i.label.indexOf(bank_name) != -1;
                })
              }
              console.log('bank = ',bank)
              category_code = bank ? bank.value : null
              setStoreInfo({
                ...storeInfo,
                bank_card_image: arr,
                bank_account: data.image2Text.bank_account,
                bank_name: bank ? bank.label : ''
              });
              querySbuBranch();
              break;
          }
        }
      } else {
        changeData(name, arr);
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (<>
   <ToLogin/>
    <div className="index-container">

      <StepNum step={step} />
      <div className="category-audit-tip_status">
        {storeBase.status == null ? (
          <span className="red">未认证</span>
        ) : (
          <span className="red">
            {storeBase.status == 5 || storeBase.status == 6
              ? infoStatus.status == 0 && infoStatus.auth_image
                ? "审核中"
                : getStatus()
              : getStatus()}
          </span>
        )}
        {storeBase.status == 2 && (storeBase.reject_reason || infoStatus.reject_reason) ? (
          <span className="rej">
            理由：{storeBase.reject_reason || infoStatus.reject_reason}
          </span>
        ) : null}
        {storeBase.status !== null &&
          (storeBase.status == 5 || storeBase.status == 6) &&
          infoStatus.status == 0 &&
          JSON.stringify(infoStatus.auth_image) == "null" && (
            <span className="blue" onClick={() => moreData(storeBase.status)}>
              去提交
            </span>
          )}
      </div>
      {step == 0 && (
        <FirstStep
          settleStatus={storeInfo.settle_status}
          first={!first}
          showMoreData={() => moreData()}
          changeIdx={(i) => chooseSettle(i)}
        />
      )}
      {/*第二步*/}
      {step == 1 && (
        <div>
          <div className="basicInfo">
            <div className="basicInfo-title">店铺基本信息</div>
            <div className="category-content_wrapper">
              <div className="title">
                店铺简称 <i className="red">*</i>
              </div>
              <div className="content">
                <input
                  disabled={!first}
                  placeholder="请填写店铺简称"
                  value={storeInfo.short_name}
                  maxLength={20}
                  className="my-input"
                  onChange={(e) => changeData("short_name", e.target.value)}
                />
              </div>
            </div>
            <Picker
              data={storeCategroy}
              cols={1}
              disabled={!first}
              onChange={(v) => changeCategroy(v)}
            >
              <div className="category-content_wrapper">
                <div className="title">
                  商品类目 <i className="red">*</i>
                </div>
                <div className="content">
                  <div className="my-input">
                    {storeInfo.store_category_name
                      ? storeInfo.store_category_name
                      : "请选择店铺经营类目"}
                  </div>
                  <i
                    className="iconfont"
                    style={{ transform: "rotate(90deg)" }}
                  >
                    &#xe6f8;
                  </i>
                </div>
              </div>
            </Picker>
            <div className="category-content_wrapper">
              <div className="title">
                店铺类型 <i className="red">*</i>
              </div>
              <div className="content">
                <div style={{ marginTop: '-10px' }}>
                  <AgreeItem
                    disabled={!first}
                    checked={storeInfo.product_type === 0}
                    onChange={() => changeData('product_type', 0)}
                  >
                    实物商品（需配送发货）
                  </AgreeItem>
                  <AgreeItem
                    disabled={!first}
                    checked={storeInfo.product_type === 1}
                    onChange={() => changeData('product_type', 1)}
                  >
                    电子卡券（系统自动生成券码，线上核销）
                  </AgreeItem>
                  <div style={{color: '#F4981D', fontSize: '12px'}}>
                    温馨提示：商品类型认证成功后，不允许修改
                  </div>
                </div>
              </div>
            </div>
            <div className="category-content_wrapper">
              <div className="title">
                人均消费 <i className="red">*</i>
              </div>
              <div className="content">
                <input
                  text="number"
                  disabled={!first}
                  placeholder="请填写人均消费"
                  value={storeInfo.average_spend}
                  className="my-input"
                  onChange={(e) => changeData("average_spend", e.target.value)}
                />
              </div>
            </div>
            {/* 起送价 */}
            {storeInfo && storeInfo.product_type == 0 && (
              <div className="category-content_wrapper">
                <div className="title">
                  起送价 <i className="red">*</i>
                </div>
                <div className="content">
                  <input
                    text="number"
                    disabled={!first}
                    placeholder="请填写起送价"
                    value={storeInfo.lowest_delivery_price}
                    className="my-input"
                    onChange={(e) => changeData("lowest_delivery_price", e.target.value)}
                  />
                </div>
              </div>
            )}
            <div
              className="category-content_wrapper"
              onClick={() => getCode(1)}
            >
              <div className="title">
                经营类别 <i className="red">*</i>
              </div>
              <div className="content">
                <div className="my-input">
                  {storeInfo.mcc_name
                    ? storeInfo.mcc_name
                    : "请选择经营类别"}
                </div>
                <i className="iconfont" style={{ transform: "rotate(90deg)" }}>
                  &#xe6f8;
                </i>
              </div>
            </div>
            <Picker
              data={storeIndustry}
              cols={1}
              disabled={!first}
              onChange={(v) => {
                setStoreInfo({
                  ...storeInfo,
                  bf_industry_code: v[0]
                })
              }}
            >
              <div className="category-content_wrapper">
                <div className="title">
                  行业归属 <i className="red">*</i>
                </div>
                <div className="content">
                  <div className="my-input">
                    {storeInfo.bf_industry_code
                      ? storeIndustry.find(t => t.value === storeInfo.bf_industry_code).label
                      : <span style={{ color: '#999' }}>请选择所属行业</span>}
                  </div>
                  <i
                    className="iconfont"
                    style={{ transform: "rotate(90deg)" }}
                  >
                    &#xe6f8;
                  </i>
                </div>
              </div>
            </Picker>
            <div
              className="category-content_wrapper"
              onClick={() => getCode(2)}
            >
              <div className="title">
                行业类目 <i className="red">*</i>
              </div>
              <div className="content">
                <div className="my-input">
                  {storeInfo.industry_name
                    ? storeInfo.industry_name
                    : "请选择行业类目"}
                </div>
                <i className="iconfont" style={{ transform: "rotate(90deg)" }}>
                  &#xe6f8;
                </i>
              </div>
            </div>
            <Address
              title={"店铺地址"}
              address={storeInfo.address}
              showAddress={true}
              disabled={!first}
              setAddress={(province, city, area, type) =>
                setAddress(province, city, area, type)
              }
              chooseName={
                storeInfo.area
                  ? storeInfo.province + storeInfo.city + storeInfo.area
                  : "请选择省/市/区"
              }
              changeData={(name, value) => changeData(name, value)}
            />
            <div className="category-content_wrapper">
              <div className="title">
                客服手机 <i className="red">*</i>
              </div>
              <div className="content">
                <input
                  disabled={!first}
                  placeholder="请填写客服手机号"
                  value={storeInfo.hot_line}
                  className="my-input"
                  onChange={(e) => changeData("hot_line", e.target.value)}
                />
              </div>
            </div>
            <div className="category-content_wrapper">
              <div className="title">
                支付宝账号 <i className="red">*</i>
              </div>
              <div className="content">
                <input
                  disabled={!first}
                  placeholder="请填写支付宝账号"
                  value={storeInfo.ali_account}
                  className="my-input"
                  onChange={(e) => changeData("ali_account", e.target.value)}
                />
              </div>
            </div>
            <div className="category-content_wrapper">
              <div className="title">推荐人手机号</div>
              <div className="content">
                <input
                  disabled={!isFirstBase}
                  placeholder="请填写推荐人的手机号码，若无可不填"
                  value={storeInfo.code}
                  className="my-input"
                  maxLength={11}
                  onBlur={(e) => queryAgent(e.target.value)}
                  onChange={(e) => changeData("code", e.target.value)}
                />
              </div>
            </div>
            {storeInfo.settle_status !== 1 && (
              <>
                <div className="upload-img-tip">
                  <ImgPicker
                    img={storeInfo.business_license}
                    title={"营业执照"}
                    OSSData={OSSData}
                    disabled={!first}
                    changeData={(e) => image2Text("business_license", e)}
                  />
                  <div className="img-tip">
                    • 上传JPG、PNG格式；
                    <br />
                    • 营业执照未过期；
                    <br />
                    • 需文字清晰、边框完整、露出国徽；
                    <br />• 复印件需加盖公章；
                    <p
                      style={{ color: "#048ad3", cursor: "pointer" }}
                      onClick={() => showExample(3)}
                    >
                      示例
                    </p>
                  </div>
                </div>
                <div className="category-content_wrapper">
                  <div className="title">
                    公司名称 <i className="red">*</i>
                  </div>
                  <div className="content">
                    <input
                      disabled={!first}
                      placeholder="请填写营业执照一致的名称"
                      value={storeInfo.company_name}
                      maxLength={50}
                      className="my-input"
                      onChange={(e) =>
                        changeData("company_name", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="category-content_wrapper">
                  <div className="title">
                    统一社会
                    <br />
                    信用代码 <i className="red">*</i>
                  </div>
                  <div className="content">
                    <input
                      disabled={!first}
                      placeholder="请填写统一社会信用代码"
                      value={storeInfo.credit_code}
                      className="my-input"
                      maxLength={30}
                      onChange={(e) =>
                        changeData("credit_code", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="category-content_wrapper">
                  <div className="date-picker-title">
                    营业执照
                    <br />
                    有效期 <i className="red">*</i>
                  </div>
                  <div className="select-date_wrapper">
                    <div className="select-date-content">
                      <DatePicker
                        mode={"date"}
                        disabled={!first || longCredit}
                        extra={
                          storeInfo.business_license_valid_start
                            ? storeInfo.business_license_valid_start
                            : "开始日期"
                        }
                        value={
                          storeInfo.business_license_valid_start
                            ? new Date(
                                Date.parse(
                                  storeInfo.business_license_valid_start
                                )
                              )
                            : ""
                        }
                        maxDate={
                          storeInfo.business_license_valid_end
                            ? new Date(
                                Date.parse(storeInfo.business_license_valid_end)
                              )
                            : ""
                        }
                        onChange={(e) =>
                          changeData(
                            "business_license_valid_start",
                            dateFormat(fmt, e)
                          )
                        }
                      >
                        <List.Item />
                      </DatePicker>
                      <span className={"select-date-to"}>至</span>
                      <DatePicker
                        mode={"date"}
                        disabled={!first || longCredit}
                        extra={
                          storeInfo.business_license_valid_end
                            ? storeInfo.business_license_valid_end
                            : "结束日期"
                        }
                        value={
                          storeInfo.business_license_valid_end
                            ? new Date(
                                Date.parse(storeInfo.business_license_valid_end)
                              )
                            : ""
                        }
                        minDate={
                          storeInfo.business_license_valid_start
                            ? new Date(
                                Date.parse(
                                  storeInfo.business_license_valid_start
                                )
                              )
                            : ""
                        }
                        maxDate={maxDate}
                        onChange={(e) =>
                          changeData(
                            "business_license_valid_end",
                            dateFormat(fmt, e)
                          )
                        }
                      >
                        <List.Item />
                      </DatePicker>
                    </div>
                    <AgreeItem
                      disabled={!first}
                      checked={longCredit}
                      data-seed="logId"
                      onChange={(e) =>
                        changeBox(
                          e,
                          1,
                          "business_license_valid_start",
                          "business_license_valid_end"
                        )
                      }
                    >
                      长期{" "}
                    </AgreeItem>
                  </div>
                </div>
                <div className="category-content_wrapper">
                  <div className="title">
                    注册资本 <i className="red">*</i>
                  </div>
                  <div className="content">
                    <input
                      type="number"
                      disabled={!first}
                      placeholder="请填写注册资本"
                      value={storeInfo.registered_capital}
                      className="my-input"
                      onChange={(e) => changeData("registered_capital", e.target.value)}
                    />
                    <div className="suffix">万元</div>
                  </div>
                </div>
              </>
            )}
            <div className="upload-img-tip">
              <ImgPicker
                img={storeInfo.house_num}
                title={"门头照"}
                OSSData={OSSData}
                disabled={!first}
                changeData={(e) => changeData("house_num", e)}
              />
              <div className="img-tip">
                • 上传JPG、PNG格式；
                <br />
                • 需拍摄完整牌匾、门框；
                <br />• 确保图像清晰；
                <p
                  style={{ color: "#048ad3", cursor: "pointer" }}
                  onClick={() => showExample(4)}
                >
                  示例
                </p>
              </div>
            </div>
            <div className="upload-img-tip">
              <ImgPicker
                img={storeInfo.cover}
                title={"店铺头像"}
                OSSData={OSSData}
                disabled={!first}
                changeData={(e) => changeData("cover", e)}
              />
              <div className="img-tip">
                • 建议上传140*140px或比例1：1;
                <br />
                • JPG、PNG格式，图片小于1M;
              </div>
            </div>
            <div className="upload-img-tip">
              <ImgPicker
                img={storeInfo.images}
                max={5}
                title={"店铺轮播图"}
                showStart
                OSSData={OSSData}
                disabled={!first}
                changeData={(e) => changeData("images", e)}
              />
              <div className="img-tip">
                • 建议上传710*300px或比例7:3;
                <br />
                • JPG、PNG格式，图片小于1M；最多上传5张图片;
              </div>
            </div>
            <div className="upload-img-tip">
              <ImgPicker
                type="video"
                img={storeInfo.video}
                title={"介绍视频"}
                showStart
                OSSData={OSSData}
                disabled={!first}
                changeData={(e) => changeData("video", e)}
              />
              <div className="img-tip">
                • 选填，建议上传710*300px或比例7:3;
                <br />
                • MP4格式；只允许上传1个视频;
              </div>
            </div>
            <div className="category-content_wrapper">
              <div className="title">
                店铺公告
              </div>
              <div className="content">
                <input
                  disabled={!first}
                  placeholder="请填写店铺公告"
                  value={storeInfo.notice}
                  className="my-input"
                  onChange={(e) => changeData("notice", e.target.value)}
                />
              </div>
            </div>
            {storeInfo.settle_status !== 1 && (
              <>
                <div className="upload-img-tip">
                  <ImgPicker
                    img={storeInfo.checkout_counter}
                    title={"收银台照"}
                    disabled={!first}
                    OSSData={OSSData}
                    changeData={(e) => changeData("checkout_counter", e)}
                  />
                  <div className="img-tip">
                    • 上传JPG、PNG格式；
                    <br />
                    • 确保图像清晰；
                    <br />
                    <p
                      style={{ color: "#048ad3", cursor: "pointer" }}
                      onClick={() => showExample(6)}
                    >
                      示例
                    </p>
                  </div>
                </div>
              </>
            )}
            <div className="upload-img-tip">
              <ImgPicker
                img={storeInfo.store_inner}
                title={"店内照"}
                disabled={!first}
                OSSData={OSSData}
                changeData={(e) => changeData("store_inner", e)}
              />
              <div className="img-tip img-tip-no-bd">
                • 上传JPG、PNG格式；
                <br />
                • 需拍摄完整店内环境；
                <br />
                • 确保图像清晰；
                <br />• 无店内照可上传租赁协议；
                <p
                  style={{ color: "#048ad3", cursor: "pointer" }}
                  onClick={() => showExample(1)}
                >
                  示例
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {/*第三步*/}
      {step == 2 && (
        <div>
          <div className="basicInfo">
            <div className="basicInfo-title">法人信息</div>
            <div className="upload-img-tip">
              <ImgPicker
                img={storeInfo.id_card_front}
                title={"身份证正面照"}
                OSSData={OSSData}
                disabled={!first}
                changeData={(e) => image2Text("id_card_front", e)}
              />
              <div className="img-tip">
                • 上传JPG、PNG格式；
                <br />
                • 证件头像清晰，文字数字清晰可辨；
                <br />
                • 个体户或企业用户上传法人身份证；
                <br />
                <p
                  style={{ color: "#048ad3", cursor: "pointer" }}
                  onClick={() => showExample(8)}
                >
                  示例
                </p>
              </div>
            </div>
            <div className="upload-img-tip">
              <ImgPicker
                img={storeInfo.id_card_back}
                title={"身份证背面照"}
                disabled={!first}
                OSSData={OSSData}
                changeData={(e) => image2Text("id_card_back", e)}
              />
              <div className="img-tip">
                • 上传JPG、PNG格式；
                <br />
                • 身份证未过期；
                <br />
                • 个体户或企业用户上传法人身份证；
                <br />
                <p
                  style={{ color: "#048ad3", cursor: "pointer" }}
                  onClick={() => showExample(7)}
                >
                  示例
                </p>
              </div>
            </div>
            {/* <div className="upload-img-tip">
              <ImgPicker
                img={storeInfo.id_card_hand}
                title={"手持身份证照"}
                OSSData={OSSData}
                disabled={!first}
                changeData={(e) => changeData("id_card_hand", e)}
              />
              <div className="img-tip">
                • 上传JPG、PNG格式；
                <br />
                • 证件头像清晰，文字数字清晰可辨；
                <br />• 手持身份证人脸清晰；
                <p
                  style={{ color: "#048ad3", cursor: "pointer" }}
                  onClick={() => showExample(9)}
                >
                  示例
                </p>
              </div>
            </div> */}
            <div className="category-content_wrapper">
              <div className="title">
                法人姓名 <i className="red">*</i>
              </div>
              <div className="content">
                <input
                  disabled={!first}
                  placeholder="个体户或企业用户请填写法人姓名"
                  value={storeInfo.corporation}
                  className="my-input"
                  maxLength={20}
                  onChange={(e) => changeData("corporation", e.target.value)}
                />
              </div>
            </div>
            <div className="category-content_wrapper">
              <div className="title">
                法人身
                <br />
                份证号 <i className="red">*</i>
              </div>
              <div className="content">
                <input
                  disabled={!first}
                  placeholder="个体户或企业用户请填写法人身份证号"
                  value={storeInfo.id_card_no}
                  className="my-input"
                  maxLength={18}
                  onChange={(e) => changeData("id_card_no", e.target.value)}
                />
              </div>
            </div>
            <div className="category-content_wrapper">
              <div className="title">
                法人身份
                <br />
                证地址 <i className="red">*</i>
              </div>
              <div className="content">
                <input
                  disabled={!first}
                  placeholder="个体户或企业用户请填写法人身份证地址"
                  value={storeInfo.id_card_address}
                  className="my-input"
                  onChange={(e) => changeData("id_card_address", e.target.value)}
                />
              </div>
            </div>
            <div className="category-content_wrapper">
              <div className="title">
                身份证有
                <br />
                效期限 <i className="red">*</i>
              </div>
              <div className="select-date_wrapper">
                <div className="select-date-content">
                  <DatePicker
                    mode={"date"}
                    disabled={!first || longIdCard}
                    extra={
                      storeInfo.id_card_valid_start
                        ? storeInfo.id_card_valid_start
                        : "开始日期"
                    }
                    value={
                      storeInfo.id_card_valid_start
                        ? new Date(Date.parse(storeInfo.id_card_valid_start))
                        : ""
                    }
                    maxDate={
                      storeInfo.id_card_valid_end
                        ? new Date(Date.parse(storeInfo.id_card_valid_end))
                        : ""
                    }
                    onChange={(e) =>
                      changeData("id_card_valid_start", dateFormat(fmt, e))
                    }
                  >
                    <List.Item />
                  </DatePicker>
                  <span className={"select-date-to"}>至</span>
                  <DatePicker
                    mode={"date"}
                    extra={
                      storeInfo.id_card_valid_end
                        ? storeInfo.id_card_valid_end
                        : "结束日期"
                    }
                    value={
                      storeInfo.id_card_valid_end
                        ? new Date(Date.parse(storeInfo.id_card_valid_end))
                        : ""
                    }
                    disabled={!first || longIdCard}
                    minDate={
                      storeInfo.id_card_valid_start
                        ? new Date(Date.parse(storeInfo.id_card_valid_start))
                        : ""
                    }
                    maxDate={new Date(2099, 11, 30, 23, 59, 59)}
                    onChange={(e) =>
                      changeData("id_card_valid_end", dateFormat(fmt, e))
                    }
                  >
                    <List.Item />
                  </DatePicker>
                </div>
                <AgreeItem
                  disabled={!first}
                  checked={longIdCard}
                  data-seed="logId"
                  onChange={(e) =>
                    changeBox(e, 2, "id_card_valid_start", "id_card_valid_end")
                  }
                >
                  长期{" "}
                </AgreeItem>
              </div>
            </div>
            <div className="category-content_wrapper">
              <div className="title">
                法人手
                <br />
                机号码 <i className="red">*</i>
              </div>
              <div className="content">
                <input
                  disabled={!first}
                  placeholder="个体户或企业用户请填写法人手机号码"
                  value={storeInfo.mobile}
                  className="my-input"
                  maxLength={18}
                  onChange={(e) => changeData("mobile", e.target.value)}
                />
              </div>
            </div>
            {storeInfo.settle_status !== 1 && (
              <div className="category-content_wrapper">
                <div className="title">
                  邮箱 <i className="red">*</i>
                </div>
                <div className="content">
                  <input
                    disabled={!first}
                    placeholder="请输入邮箱"
                    value={storeInfo.email}
                    className="my-input"
                    onChange={(e) => changeData("email", e.target.value)}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="basicInfo">
            <div className="basicInfo-title">受益人2{storeInfo.settle_status != 2 && '（非必填）'}</div>
            {/* <div className="category-content_wrapper" style={{ padding: 0 }}>
              <AgreeItem
                disabled={!first}
                checked={same}
                data-seed="logId"
                onChange={(e) => changeSame(e)}
              >
                受益人与法人信息一致{" "}
              </AgreeItem>
            </div> */}
            <div className="upload-img-tip">
              <ImgPicker
                img={storeInfo.contact_card_front}
                title={"受益人身份证正面照"}
                showStart={storeInfo.settle_status != 2}
                OSSData={OSSData}
                disabled={!first}
                changeData={(e) => image2Text("contact_card_front", e)}
              />
              <div className="img-tip">
                • 上传JPG、PNG格式；
                <br />
                • 证件头像清晰，文字数字清晰可辨；
                <br />
                • 个体户或企业用户上传法人身份证；
                <br />
                <p
                  style={{ color: "#048ad3", cursor: "pointer" }}
                  onClick={() => showExample(8)}
                >
                  示例
                </p>
              </div>
            </div>
            <div className="upload-img-tip">
              <ImgPicker
                img={storeInfo.contact_card_back}
                title={"受益人身份证背面照"}
                showStart={storeInfo.settle_status != 2}
                disabled={!first}
                OSSData={OSSData}
                changeData={(e) => image2Text("contact_card_back", e)}
              />
              <div className="img-tip">
                • 上传JPG、PNG格式；
                <br />
                • 身份证未过期；
                <br />
                • 个体户或企业用户上传法人身份证；
                <br />
                <p
                  style={{ color: "#048ad3", cursor: "pointer" }}
                  onClick={() => showExample(7)}
                >
                  示例
                </p>
              </div>
            </div>
            <div className="category-content_wrapper">
              <div className="title">
                受益人姓名{storeInfo.settle_status == 2 && <i className="red">*</i>}
              </div>
              <div className="content">
                <input
                  disabled={!first}
                  placeholder="请填写受益人姓名"
                  value={storeInfo.contact_name}
                  className="my-input"
                  maxLength={20}
                  onChange={(e) => changeData("contact_name", e.target.value)}
                />
              </div>
            </div>
            <div className="category-content_wrapper">
              <div className="title" style={{ display: 'block' }}>
                受益人身{storeInfo.settle_status == 2 && <i className="red">*</i>}
                <br />
                份证号
              </div>
              <div className="content">
                <input
                  disabled={!first}
                  placeholder="请填写受益人身份证号"
                  value={storeInfo.contact_id_card}
                  className="my-input"
                  maxLength={18}
                  onChange={(e) =>
                    changeData("contact_id_card", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="category-content_wrapper">
              <div className="title" style={{ display: 'block' }}>
                受益人身{storeInfo.settle_status == 2 && <i className="red">*</i>}
                <br />
                份证地址
              </div>
              <div className="content">
                <input
                  disabled={!first}
                  placeholder="个体户或企业用户请填写法人身份证地址"
                  value={storeInfo.contact_card_address}
                  className="my-input"
                  onChange={(e) => changeData("contact_card_address", e.target.value)}
                />
              </div>
            </div>
            <div className="category-content_wrapper">
              <div className="title" style={{ display: 'block' }}>
                受益人身份{storeInfo.settle_status == 2 && <i className="red">*</i>}
                <br />
                证有效期限
              </div>
              <div className="select-date_wrapper">
                <div className="select-date-content">
                  <DatePicker
                    mode={"date"}
                    disabled={!first || longIdCard2}
                    extra={
                      storeInfo.contact_card_valid_start
                        ? storeInfo.contact_card_valid_start
                        : "开始日期"
                    }
                    value={
                      storeInfo.contact_card_valid_start
                        ? new Date(Date.parse(storeInfo.contact_card_valid_start))
                        : ""
                    }
                    maxDate={
                      storeInfo.contact_card_valid_end
                        ? new Date(Date.parse(storeInfo.contact_card_valid_end))
                        : ""
                    }
                    onChange={(e) =>
                      changeData("contact_card_valid_start", dateFormat(fmt, e))
                    }
                  >
                    <List.Item />
                  </DatePicker>
                  <span className={"select-date-to"}>至</span>
                  <DatePicker
                    mode={"date"}
                    extra={
                      storeInfo.contact_card_valid_end
                        ? storeInfo.contact_card_valid_end
                        : "结束日期"
                    }
                    value={
                      storeInfo.contact_card_valid_end
                        ? new Date(Date.parse(storeInfo.contact_card_valid_end))
                        : ""
                    }
                    disabled={!first || longIdCard2}
                    minDate={
                      storeInfo.contact_card_valid_start
                        ? new Date(Date.parse(storeInfo.contact_card_valid_start))
                        : ""
                    }
                    maxDate={new Date(2099, 11, 30, 23, 59, 59)}
                    onChange={(e) =>
                      changeData("contact_card_valid_end", dateFormat(fmt, e))
                    }
                  >
                    <List.Item />
                  </DatePicker>
                </div>
                <AgreeItem
                  disabled={!first}
                  checked={longIdCard2}
                  data-seed="logId"
                  onChange={(e) =>
                    changeBox(e, 2, "contact_card_valid_start", "contact_card_valid_end")
                  }
                >
                  长期{" "}
                </AgreeItem>
              </div>
            </div>
            <div className="category-content_wrapper">
              <div className="title">
                受益人电话{storeInfo.settle_status == 2 && <i className="red">*</i>}
              </div>
              <div className="content">
                <input
                  disabled={!first}
                  placeholder="请填写手机号码或电话号码"
                  value={storeInfo.contact_mobile}
                  className="my-input"
                  type="number"
                  onChange={(e) => changeData("contact_mobile", e.target.value)}
                />
              </div>
            </div>
            <div className="category-content_wrapper">
              <div className="title">
                受益人邮箱{storeInfo.settle_status == 2 && <i className="red">*</i>}
              </div>
              <div className="content">
                <input
                  disabled={!first}
                  placeholder="请填写受益人邮箱"
                  value={storeInfo.contact_email}
                  className="my-input"
                  onChange={(e) => changeData("contact_email", e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {/*第四步*/}
      {step == 3 && (
        <div>
          <div className="basicInfo">
            <div className="basicInfo-title">结算银行信息</div>
            <div className="upload-img-tip">
              <ImgPicker
                img={storeInfo.bank_id_card_front}
                title={"开户人身份证正面照"}
                disabled={!first}
                OSSData={OSSData}
                changeData={(e) => image2Text("bank_id_card_front", e)}
              />
              <div className="img-tip">
                • 上传JPG、PNG格式；
                <br />
                • 证件头像清晰，文字数字清晰可辨；
                <br />
                • 对公账户请上传法人身份证；
                <br />
                • 个体户或企业用户上传法人身份证；
                <br />
                <p
                  style={{ color: "#048ad3", cursor: "pointer" }}
                  onClick={() => showExample(8)}
                >
                  示例
                </p>
              </div>
            </div>
            <div className="upload-img-tip">
              <ImgPicker
                img={storeInfo.bank_id_card_back}
                title={"开户人身份证背面照"}
                disabled={!first}
                OSSData={OSSData}
                changeData={(e) => changeData("bank_id_card_back", e)}
              />
              <div className="img-tip">
                • 上传JPG、PNG格式；
                <br />
                • 身份证未过期；
                <br />
                • 对公账户请上传法人身份证；
                <br />
                • 个体户或企业用户上传法人身份证；
                <br />
                <p
                  style={{ color: "#048ad3", cursor: "pointer" }}
                  onClick={() => showExample(7)}
                >
                  示例
                </p>
              </div>
            </div>
            {storeInfo.settle_status != 2 && (
              <div className="upload-img-tip">
                <ImgPicker
                  img={storeInfo.bank_card_image}
                  title={"结算银行卡照"}
                  disabled={!first}
                  OSSData={OSSData}
                  changeData={(e) => image2Text("bank_card_image", e)}
                />
                <div className="img-tip">
                  • 上传JPG、PNG格式；
                  <br />
                  • 证件文字数字清晰可辨；
                  <br />
                  <p
                    style={{ color: "#048ad3", cursor: "pointer" }}
                    onClick={() => showExample(5)}
                  >
                    示例
                  </p>
                </div>
              </div>
            )}
            {/* {storeInfo.settle_status != 2 && (
              <div className="upload-img-tip">
                <ImgPicker
                  img={storeInfo.bank_hand_image}
                  title={"手持银行卡照"}
                  OSSData={OSSData}
                  disabled={!first}
                  changeData={(e) => changeData("bank_hand_image", e)}
                />
                <div className="img-tip">
                  • 上传JPG、PNG格式；
                  <br />
                  • 证件文字数字清晰可辨；
                  <br />• 手持身份证人脸清晰；
                  <p
                    style={{ color: "#048ad3", cursor: "pointer" }}
                    onClick={() => showExample(11)}
                  >
                    示例
                  </p>
                </div>
              </div>
            )} */}
            {storeInfo.settle_status == 2 && (
              <div className="upload-img-tip">
                <ImgPicker
                  img={storeInfo.opening_permit_image}
                  title={"开户许可证"}
                  OSSData={OSSData}
                  disabled={!first}
                  showStart={
                    storeInfo.settle_status === 0 &&
                    storeInfo.bank_account_type !== 0
                  }
                  changeData={(e) => changeData("opening_permit_image", e)}
                />
                <div className="img-tip img-tip-no-bd">
                  • 上传JPG、PNG格式；
                  <br />
                  • 证件文字数字清晰可辨；
                  <br />
                  <p
                    style={{ color: "#048ad3", cursor: "pointer" }}
                    onClick={() => showExample(2)}
                  >
                    示例
                  </p>
                </div>
              </div>
            )}
            <Picker
              data={bankType}
              cols={1}
              disabled={true}
              value={storeInfo.settle_status == 2 ? "对公" : "对私"}
              onChange={(v) => changeData("bank_account_type", v[0])}
            >
              <div className="category-content_wrapper">
                <div className="title">
                  账户类型 <i className="red">*</i>
                </div>
                <div className="content">
                  <div className="my-input">
                    {storeInfo.settle_status == 2 ? "对公" : "对私"}
                  </div>
                  <i
                    className="iconfont"
                    style={{ transform: "rotate(90deg)" }}
                  >
                    &#xe6f8;
                  </i>
                </div>
              </div>
            </Picker>
            <div className="category-content_wrapper">
              <div className="title">
                银行卡号 <i className="red">*</i>
              </div>
              <div className="content">
                <input
                  disabled={!first}
                  placeholder="请填写银行卡号"
                  value={storeInfo.bank_account}
                  className="my-input"
                  maxLength={30}
                  onChange={(e) => changeData("bank_account", e.target.value)}
                />
              </div>
            </div>
            <Picker
              data={unionpayBankCategories}
              cols={1}
              disabled={!first}
              onChange={(v) => changeBank(v)}
            >
              <div className="category-content_wrapper">
                <div className="title">
                  银行名称 <i className="red">*</i>
                </div>
                <div className="content">
                  <div className="my-input">
                    {storeInfo.bank_name
                      ? storeInfo.bank_name
                      : "请填写银行名称"}
                  </div>
                  <i
                    className="iconfont"
                    style={{ transform: "rotate(90deg)" }}
                  >
                    &#xe6f8;
                  </i>
                </div>
              </div>
            </Picker>
            <SbuBranchAreaPicker
              title={"开户支行省市"}
              disabled={!first}
              setRegin={(province, city, area) =>
                setRegin([province, city, area])
              }
              chooseName={
                storeInfo.bank_city
                  ? storeInfo.bank_province +
                    storeInfo.bank_city +
                    storeInfo.bank_area
                  : "请选择开户支行省/市/区"
              }
            />
            <div
              className="category-content_wrapper"
              onClick={() => {
                setModalPicker({
                  show: true,
                  title: '请选择开户支行',
                  arr: unionpayBank,
                  code: 'bank_code',
                  name: 'bank_branch'
                })
              }}
            >
              <div className="title">
                支行名称 <i className="red">*</i>
              </div>
              <div className="content">
                <div className="my-input">
                  {storeInfo.bank_branch
                    ? storeInfo.bank_branch
                    : "请选择开户支行"}
                </div>
                <i className="iconfont" style={{ transform: "rotate(90deg)" }}>
                  &#xe6f8;
                </i>
              </div>
            </div>
            <div className="category-content_wrapper">
              <div className="title">
                开户名称 <i className="red">*</i>
              </div>
              <div className="content">
                <input
                  disabled={!first}
                  placeholder="对私填写持卡人姓名，对公填写公司名称"
                  value={storeInfo.bank_account_name}
                  className="my-input"
                  maxLength={20}
                  onChange={(e) =>
                    changeData("bank_account_name", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="category-content_wrapper">
              <div className="title">
                开户人证
                <br />
                件号码 <i className="red">*</i>
              </div>
              <div className="content">
                <input
                  disabled={!first}
                  placeholder="请填写开户人身份证号码"
                  value={storeInfo.bank_account_id_card_no}
                  className="my-input"
                  maxLength={18}
                  onChange={(e) =>
                    changeData("bank_account_id_card_no", e.target.value)
                  }
                />
              </div>
            </div>
            {storeInfo.settle_status == 1 && (
              <div className="category-content_wrapper">
                <div className="title">
                  预留手机号 <i className="red">*</i>
                </div>
                <div className="content">
                  <input
                    type="tel"
                    pattern="^\d+$"
                    disabled={!first}
                    placeholder="请填写银行卡预留手机号"
                    value={storeInfo.bank_mobile}
                    className="my-input"
                    maxLength={11}
                    onChange={(e) => changeData("bank_mobile", e.target.value)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {/*封装的picker*/}
      <PickerModal
        show={modalPicker.show}
        close={(v,name)=>closeModal(v,name)}
        arr={modalPicker.arr}
        title={modalPicker.title}
      />
      <Modal
        visible={showModal}
        transparent
        maskClosable={false}
        closable={true}
        onClose={() => {
          setModal(false);
        }}
        wrapClassName={"my-modal"}
      >
        <div className="modal-img-wrapper">
          <img
            style={{ width: "100%", objectFit: "cover" }}
            src={require(`../../static/images/example/${modalImg}.jpg`)}
            alt=""
          />
        </div>
      </Modal>
        <div className="btn-wrapper">
          {step !== 0 && (
            <div
              onClick={() => {
                setStep(step - 1);
              }}
              className="btn2"
              style={{ marginRight: 40 }}
            >
              上一步
            </div>
          )}
          {step !== 0 && (
            <div
              onClick={() => submit()}
              className="btn2 btn-active"
              style={{backgroundColor:step == 3 && !first ?'rgba(244,152,29,0.6)':'#F4981D'}}
            >
              {step == 3 ? "提交" : "下一步"}
            </div>
          )}
        </div>
        {step == 0 && (
          <div onClick={() => submit()} className="btn2 btn-active step0">
            下一步
          </div>
        )}
    </div>
  </>);
};

export default Index;
