import { Toast } from "antd-mobile";
import app from "../app.json";
export const userAgent = navigator.userAgent;
export const isAndroid =
  userAgent.indexOf("Android") > -1 || userAgent.indexOf("Adr") > -1; //android终端
export const isIOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
export const isMobile = !!userAgent.match(/AppleWebKit.*Mobile.*/); //是否为移动终端
export const isNonEmptyArray = (value) => {
  return Array.isArray(value) && value.length > 0;
};

export const verification = (rule) => {
  for (let item of rule) {
    if (item.rule) {
      Toast.info(item.title);
      return false;
    }
  }
  return true;
};

// 统一社会信用编码
export const checkSocialCreditCode = /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/;

// 身份证
export const isCardNo = (card) => {
  // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
  let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
  if (reg.test(card) === false) {
    return false;
  }
  return true;
};

// 处理传给后台的图片地址
export const handleImg = (urls) => {
  if (urls?.length > 0) {
    const formatList = urls.map((item) =>
      item.url.replace(`${app.oss_host}`, "")
    );
    return urls.length > 1 ? formatList : formatList.join("");
  }
  return ''
};

export const operateImg = (data, thumbUrl, s) => {
  let b = [];
  let a = {};
  a.url = data;
  a.uid = new Date().getTime();
  a.name = new Date().getTime();
  if (thumbUrl) {
    a.thumbUrl = thumbUrl;
  }
  b.push(a);
  s([...b]);
};

export const getQueryString = (name) => {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return decodeURI(r[2]);
  return null;
};

export const curryingCheck = (reg) => (txt) => reg.test(txt);

export const isPhone = curryingCheck(/^1[123456789]\d{9}$/);

export const dateFormat = (fmt, date) => {
  if (date) {
    let ret;
    const opt = {
      "Y+": date.getFullYear().toString(), // 年
      "M+": (date.getMonth() + 1).toString(), // 月
      "D+": date.getDate().toString(), // 日
      "h+": date.getHours().toString(), // 时
      "m+": date.getMinutes().toString(), // 分
      "s+": date.getSeconds().toString(), // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt);
      if (ret) {
        fmt = fmt.replace(
          ret[1],
          ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
        );
      }
    }
    return fmt;
  }
  return "";
};
//字符串判空
export const isEmpty = (obj) => {
  if (typeof obj == "undefined" || obj == null || obj == "") {
    return true;
  } else {
    return false;
  }
};

// 时间格式化
export const YMDHMFormatDate = (input) => {
  if (!input) return "";
  let date = new Date(input * 1000);
  let Y = date.getFullYear() + "-";
  let M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  let D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
  let h =
    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
  let m =
    (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
    ":";
  let s =
    (date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()) + "";

  return Y + M + D + h + m + s;
};
//半隐式登录手机号
export function semiHidingMobile(mobile) {
  if (mobile.length >= 9) {
    let reg = mobile.substring(2, 7);
    mobile = mobile.replace(reg, "*****");
  }
  return mobile;
}


