/*
 * @Author: Fan
 * @Date: 2020-12-01 10:27:52
 * @LastEditors: Fan
 * @LastEditTime: 2020-12-02 10:12:12
 * @Description: file content
 */
import React from "react";
import { withRouter } from "react-router";
import "@/assets/less/common.less";
import "./app.less";
import Router from './router';
import 'lib-flexible';
//热更新包裹器
import { hot } from 'react-hot-loader/root'
// 函数组件 app
// console.log=function(){}

const App = props => {

  return <div>
    <Router/>
  </div>
};

export default hot(withRouter(App));

