/*
 * @Author: Fan
 * @Date: 2020-12-01 10:27:52
 * @LastEditors: Fan
 * @LastEditTime: 2020-12-02 17:20:20
 * @Description: file content
 */
import React, { useEffect, useState, useRef, ReactDOM } from "react";
import { INVITATION_USER } from "@/graphql/store";
import ToLogin from "@/components/ToLogin";
import client from "@/graphql/apollo-config";
import { ListView } from "antd-mobile";
import Cookies from "js-cookie";
import avatar from "@/assets/images/avater.png";
import LoadingFooter, {
  WAITING,
  LOADING,
  LOADED,
  FAILURE,
} from "@/components/LoadingFooter";
import "./index.less";
import { useHistory } from "react-router-dom";
import { YMDHMFormatDate } from "@/utils/index";
const renderItem = (rowData) => {
  return (
    <div className="recordlist_wrapper">
      <div className="left-wrapper">
        <img
          className="image"
          src={rowData.avatar ? rowData.avatar.url : avatar}
        />
        <span className="name">{rowData.username}</span>
      </div>
      <div className="time">{YMDHMFormatDate(rowData.bind_parent_time)}</div>
    </div>
  );
};
const Invitation = () => {
  const [height] = useState(() => document.documentElement.clientHeight);
  const list = useRef();
  const { push } = useHistory();
  const [dataSource, setDataSource] = useState(
    new ListView.DataSource({
      dataBlob: [],
      rowHasChanged: (row1, row2) => row1 !== row2,
      sectionHeaderHasChanged: (s1, s2) => s1 !== s2,
    })
  );
  const [loadingStatus, setStatus] = useState(WAITING);
  const [childUser, setChildUser] = useState({
    current_page: 0,
  });
  useEffect(() => {
    const storeId = Cookies.get("store_id");
    if (!storeId) {
      push("/");
      return;
    }
    init();
  }, []);

  const init = async () => {
    setStatus(LOADING);
    try {
      const { data } = await client.query({
        query: INVITATION_USER,
        variables: {
          limit: 20,
          page: childUser.current_page + 1,
        },
        fetchPolicy: "no-cache",
      });
      if (data) {
        const d = data.childUser;
        setStatus(!d.has_more_pages ? LOADED : WAITING);
        const newList = list.current ? [...list.current, ...d.data] : d.data;
        setChildUser(d);
        list.current = newList;
        setDataSource(dataSource.cloneWithRows(newList));
      }
    } catch (error) {
      setStatus(FAILURE);
    }
  };
  const onError = () => {
    init();
  };
  const onEndReached = () => {
    if (loadingStatus == WAITING) {
      init();
    }
  };
  return Array.isArray(list.current) && list.current.length == 0 ? (
    <>
      <ToLogin />
      <div className="no-data">
        <img src={require("../../assets/images/empty.png")} alt="" />
        <p>暂无数据</p>
      </div>
    </>
  ) : (
    <ListView
      dataSource={dataSource}
      className={"invitation-wrapper"}
      renderHeader={() => (
        <>
          <ToLogin />
          <div className="invitation-text_wrapper">
            <span className="invitation-text">
              累计邀请人数：{childUser?.total}
            </span>
          </div>
        </>
      )}
      renderFooter={() => (
        <LoadingFooter status={loadingStatus} onError={onError} />
      )}
      renderRow={renderItem}
      style={{
        height: height,
        overflow: "auto",
      }}
      pageSize={10} //每次事件循环（每帧）渲染的行数
      initialListSize={20}
      scrollRenderAheadDistance={500}
      onEndReached={onEndReached}
      onEndReachedThreshold={10}
    />
  );
};

export default Invitation;
