import React, { useMemo, useReducer, useState,useEffect } from 'react';
import { Flex, Switch, List, Radio, } from 'antd-mobile';
import './index.less';
import Address from "@/components/address"
import ImgPicker from "@/components/imgPicker"
import { useQuery, useLazyQuery, useMutation } from "react-apollo";
import {
  STORE_AUDIT_INFO,
  ADD_STORE_BASE,
} from "@/graphql/store";
import client from "@/graphql/apollo-config";

const RadioItem = Radio.RadioItem;

const Store = (props) => {
  const [value, setValue] = useState(0)

  const data = [
    { value: 0, label: 'doctor' },
    { value: 1, label: 'bachelor' },
  ];

  useEffect(() => {
    // getStoreInfo();
  }, []);

  const onChange = () =>{

  }

  return <div className="store-container">
    <div className="store-top">
      <div className="store-top_title">
        店铺营业开关
      </div>
      <div>
        <Switch
          checked={true}
          onChange={() => {}}
        />
      </div>
    </div>
    <div className="category-wrapper">
      <div className="category-content_wrapper">
      <div className="title">
        店铺类型 <i className="red">*</i>
      </div>
      <div className="content">
        <Flex>
          <Flex.Item>
            <Radio className="my-radio" onChange={e => console.log('checkbox', e)}>实物商品</Radio>
            <Radio className="my-radio" onChange={e => console.log('checkbox', e)}>电子卡券</Radio>
          </Flex.Item>
        </Flex>
      </div>
    </div>
      <div className="category-content_wrapper">
        <div className="title">
          消费设置 <i className="red">*</i>
        </div>
        <div className="content-wrapper">
          <div className="content">
            <span>人均消费</span>
            <input
              maxLength={25}
              className="my-input"
            />
            <span>元</span>
          </div>
          <div className="content content-i">
            <span>起送价</span>
            <input
              maxLength={25}
              className="my-input"
            />
            <span>元</span>
          </div>
          <div className="content content-i">
            <span>配送费</span>
            <input
              maxLength={25}
              className="my-input"
            />
            <span>元</span>
          </div>
        </div>
      </div>
      <ImgPicker/>
      <div className="category-content_wrapper">
        <div className="title">
          客服电话 <i className="red">*</i>
        </div>
        <div className="content">
          <input
            placeholder="请输入手机号码或电话号码"
            maxLength={25}
            className="my-input"
          />
        </div>
      </div>
      <div className="category-content_wrapper">
        <div className="title">
          店铺公告 <i className="red"></i>
        </div>
        <div className="content" style={{borderBottomWidth:0}}>
          <textarea
            placeholder="选填，店铺公告可输入新店开业优惠信息、店铺促销信息、店铺服务承诺等 "
            rows='4'
            className="my-input"
          />
        </div>
      </div>
    </div>
    <div className="category-wrapper">
      <div className="category-content_wrapper">
        <div className="title">
          企业名称<i className="red">*</i>
        </div>
        <div className="content">
          <input
            placeholder="请输入与营业执照相同的名称"
            maxLength={25}
            className="my-input"
          />
        </div>
      </div>
      <div className="category-content_wrapper">
        <div className="title">
          统一社会<br/>
          信用代码
          <i className="red">*</i>
        </div>
        <div className="content">
          <input
            placeholder="请输入统一社会信用代码"
            maxLength={25}
            className="my-input"
          />
        </div>
      </div>
      <ImgPicker/>
      <div className="category-content_wrapper">
        <div className="title">
          法人姓名
          <i className="red">*</i>
        </div>
        <div className="content">
          <input
            placeholder="请输入统一社会信用代码"
            maxLength={25}
            className="my-input"
          />
        </div>
      </div>
      <div className="category-content_wrapper">
        <div className="title">
          法人身份<br/>证
          <i className="red">*</i>
        </div>
        <div className="content">
          <input
            placeholder="请输入法人身份证号码"
            maxLength={25}
            className="my-input"
          />
        </div>
      </div>
      <ImgPicker/>
    </div>
    <div className="btn-wrapper">
      <div className="btn" style={{marginRight:20}}>上一步</div>
      <div className="btn" style={{marginLeft:20}}>提交</div>
    </div>
  </div>
};

export default Store;
