const userAgent = navigator.userAgent;
const isIOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
const imgThreshold = 1024 * 1024; //限制1M大小
const isCurrentVersionSupport = (lastVersion = "") => {
  const lowerCase = userAgent.toLowerCase();
  const chromeVersion = lowerCase.match(/chrome\/([\d\.]+)/);
  let result = true;
  if (isIOS) {
    const version = lowerCase.match(/cpu iphone os (.*?) like mac os/);
    let currentVersion = "0.0.0";
    if (version && version[1]) {
      currentVersion = version[1].replace(/_/g, ".");
    }

    const sources = currentVersion.split(".");
    const dests = lastVersion.split(".");
    const maxL = Math.max(sources.length, dests.length);

    for (let i = 0; i < maxL; i++) {
      let preValue = sources.length > i ? sources[i] : 0;
      let preNum = isNaN(Number(preValue))
        ? preValue.charCodeAt()
        : Number(preValue);
      let lastValue = dests.length > i ? dests[i] : 0;
      let lastNum = isNaN(Number(lastValue))
        ? lastValue.charCodeAt()
        : Number(lastValue);
      if (preNum < lastNum) {
        result = false;
        break;
      } else if (preNum > lastNum) {
        result = true;
        break;
      }
    }
  } else if (chromeVersion && chromeVersion[1]) {
    result = 70 > chromeVersion[1].slice(0, 2);
  }
  return result;
};

const judgeCompress = (base64) => {
  let imageSize = base64.length;
  var threshold = imgThreshold; //阈值,可根据实际情况调整 1M
  if (imageSize > threshold) {
    return true;
  }
  return false;
};
const getMimeType = function (urlData) {
  var arr = urlData.split(",");
  var mime = arr[0].match(/:(.*?);/)[1];
  return mime;
};
/**压缩图片 */
/**
 *
 * @param {*} base64
 * @param {*限制width} w
 * @param {*} orientation
 */
const compress = (base64, w, orientation) => {
  var newImage = new Image();

  var quality = 0.96; //压缩比例
  return new Promise((resolve, reject) => {
    var newBase64;
    newImage.src = base64;
    newImage.onload = async () => {
      // 绘制canvas 压缩图片
      const { width, height } = newImage;
      let canvasWidth = width;
      let canvasHeight = height;
      if (Math.max(width, height) > w) {
        if (width > height) {
          canvasWidth = w;
          canvasHeight = (w * height) / width;
        } else {
          canvasHeight = w;
          canvasWidth = (w * width) / height;
        }
      }
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
      const support = await isCurrentVersionSupport("13.4.0");
    //   alert(support + "---" + userAgent.toLowerCase() + "---" + orientation);
      if (!support && orientation > 1) {
        modify(canvas, ctx, orientation, newImage);
      } else {
        ctx.drawImage(newImage, 0, 0, canvasWidth, canvasHeight);
      }

      newBase64 = canvas.toDataURL(getMimeType(base64), quality);
      quality = quality - 0.05;
      while (newBase64.length > imgThreshold) {
        //1M
        quality -= 0.01;
        newBase64 = canvas.toDataURL("image/jpeg", quality);
      }
      resolve(newBase64);
    };
  });
};
const modify = (canvas, context, orientation, image) => {
  const { width, height } = image;
  switch (orientation) {
    case 3:
      canvas.width = width;
      canvas.height = height;
      context.rotate(Math.PI);
      context.drawImage(image, -width, -height, width, height);
      break;
    case 6:
      canvas.width = height;
      canvas.height = width;
      context.rotate(0.5 * Math.PI);
      context.drawImage(image, 0, -height, width, height);
      break;
    case 8:
      canvas.width = height;
      canvas.height = width;
      context.rotate((3 * Math.PI) / 2);
      context.drawImage(image, -width, 0, width, height);
      break;
    default:
      break;
  }
};
/** 处理数据*/
/**
 *
 * @param {*} base64 图片base64
 * @param {*} w 压缩宽
 * @param {*} orientation 旋转参数
 */
const handleImageData = (base64, w = 2000, orientation) => {
  return new Promise((resolve, reject) => {
    compress(base64, w, orientation).then((newBase64) => {
      resolve(newBase64);
    });
  });
};
export default handleImageData;
