import React, { useEffect, useState } from "react";
import { Toast, Modal } from "antd-mobile";
import "./index.less";
import { handleImg } from "@/utils/index";
import { ADDITIONAL, ADDITIONALINFO } from "@/graphql/store";
import ImgPicker from "@/components/imgPicker";
import client from "@/graphql/apollo-config";

const OtherInfo = (props) => {
  const [verifyAmount, setVerifyAmount] = useState(""); // 验证金额
  const [authImage, setAuthImage] = useState([]); // 验证图片
  const [OSSData, setOSSData] = useState({});
  const [showModal, setModal] = useState(false);
  const [modalImg, setModalImg] = useState(1);

  const settleStatus = props.location.search.slice(6, 7);
  const status = props.location.search.slice(15);
  useEffect(() => {
    moreData();
  }, []);

  const imgData = (url, id) => {
    if (url) {
      let c = {};
      c.url = url;
      c.id = id;
      return [c];
    } else {
      return [];
    }
  };

  const showExample = (img) => {
    setModal(true);
    setModalImg(img);
  };

  const moreData = async () => {
    try {
      const { data } = await client.query({
        query: ADDITIONALINFO,
        fetchPolicy: "no-cache",
      });
      if (data) {
        setOSSData({
          ...data.oss,
        });
        const a = data.additionalInfo;
        setVerifyAmount(a.verify_amount);
        setAuthImage(imgData(a.auth_image && a.auth_image.url, "auth_image"));
      }
    } catch (error) {}
  };

  //提交补充资料
  const upData = () => {
    if (status == 5) {
      if (!verifyAmount) {
        Toast.info("请输入验证金额");
      } else if (
        verifyAmount.toString().indexOf(".") !== -1 &&
        verifyAmount.toString().split(".")[1].length > 2
      ) {
        Toast.info("请输入最多两位小数的验证金额");
      } else {
        queryAdditional();
      }

      // if (!verifyAmount) {
      //   if (JSON.stringify(authImage) == 'null' || authImage.length < 1) {
      //     Toast.info('请输入验证金额')
      //   }else{
      //     // 执行请求
      //     console.log('执行请求')
      //     queryAdditional()
      //   }
      // }else{
      //   if(verifyAmount.toString().indexOf(".") !== -1 &&
      //     verifyAmount.toString().split(".")[1].length > 2){
      //     Toast.info('请输入最多两位小数的验证金额')
      //   }else{
      //     // 执行请求
      //     console.log('执行请求')
      //     queryAdditional()
      //   }
      // }
    } else {
      if (JSON.stringify(authImage) == "null" || authImage.length < 1) {
        Toast.info("请上传认证成功的截图");
      } else {
        // 执行请求
        console.log("执行请求");
        queryAdditional();
      }
    }
  };

  const queryAdditional = async () => {
    try {
      const { data } = await client.query({
        query: ADDITIONAL,
        variables: {
          settle_status: settleStatus,
          verify_amount: verifyAmount ? verifyAmount : 0,
          auth_image: handleImg(authImage) ? handleImg(authImage) : "",
        },
        fetchPolicy: "no-cache",
      });
      if (data.additional) {
        Toast.info("提交成功");
        setTimeout(() => {
          props.history.push(`/?type=${settleStatus}`);
        }, 1000);
      }
    } catch (error) {}
  };

  return (
    <div className="otherinformation-container">
      <div>
        {status == 5 && (
          <div className="basicInfo">
            <div className="basicInfo-title">打款验证</div>
            <div className="category-content_wrapper">
              <div className="title">¥</div>
              <div className="content">
                <input
                  placeholder="请您输入网商给您打款的金额进行验证"
                  value={verifyAmount || ""}
                  className="my-input"
                  type="number"
                  onChange={(e) => {
                    setVerifyAmount(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="basecInfo-tips">
              请您输入网商给您打款的金额进行认证
            </div>
          </div>
        )}
        {status == 6 && (
          <div className="basicInfo">
            <div className="basicInfo-title">商户授权认证</div>
            <div className="basecInfo-tips">
              请法人使用微信扫码商户授权认成功后，上传认证成功的截图
            </div>
            <div className="upload-image_wrapper">
              <img
                className="upload-i"
                src={require("../../assets/images/pay.png")}
                alt=""
              />
              <div className="upload-img-tip">
                <ImgPicker
                  img={authImage}
                  title={""}
                  OSSData={OSSData}
                  changeData={(e) => setAuthImage(e)}
                />
                <div className="img-tip">
                  • 上传JPG、PNG格式
                  <p
                    style={{ color: "#048ad3", cursor: "pointer" }}
                    onClick={() => showExample(10)}
                  >
                    示例
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Modal
        visible={showModal}
        transparent
        maskClosable={false}
        closable={true}
        onClose={() => {
          setModal(false);
        }}
        wrapClassName={"my-modal"}
      >
        <div className="modal-img-wrapper">
          <img
            style={{ width: "100%", objectFit: "cover" }}
            src={require(`../../static/images/example/${modalImg}.jpg`)}
            alt=""
          />
        </div>
      </Modal>
      <div className="btn-wrapper" onClick={() => upData()}>
        <div className="btn">提交</div>
      </div>
    </div>
  );
};

export default OtherInfo;
