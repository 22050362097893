/*
 * @Author: Fan
 * @Date: 2020-12-01 17:06:10
 * @LastEditors: Fan
 * @LastEditTime: 2020-12-02 10:19:40
 * @Description: file content
 */
import React, {useEffect, useState} from 'react';
import {Picker} from 'antd-mobile';
import client from "@/graphql/apollo-config";
import {UNIONPAYREGIONS} from "../graphql/store";

const SbuBranchAreaPicker = (props) => {
  const {disabled, title, setRegin, chooseName} = props;
  const [regions, setRegions] = useState([]);
  useEffect(() => {
    queryRegions();
  }, []);
  const queryRegions = async () => {
    try {
      const {data} = await client.query({
        query: UNIONPAYREGIONS,
        fetchPolicy: "no-cache"
      });
      if (data) {
        let regions = [];
        const {provinces, citys, areas} = data;
        provinces.map((value) => {
          const ctfilter = citys.filter((i) => {
            return i.parent_code === value.code
          })
          let ct = [];
          ctfilter.map((i) => {
            const areafilter = areas.filter((j) => {
              return j.parent_code === i.code
            })
            let area = [];
            areafilter.map((i) => {
              area.push({'value': i.id, 'label': i.name, 'code': i.code});
              return i;
            })
            ct.push({'value': i.id, 'label': i.name, 'code': i.code, 'children': area});
            return i;
          })
          regions.push({'value': value.id, 'label': value.name, 'code': value.code, 'children': ct});
          return value
        })
        setRegions(regions)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const changeRegin = (v) => {
    const province = regions.find((i) => {
      return i.value === v[0]
    })
    const city = province.children.find((i) => {
      return i.value === v[1]
    })
    const area = city.children.find((i) => {
      return i.value === v[2]
    })
    let copyProvince = Object.create(province);
    delete copyProvince.children;
    console.log(copyProvince, city, area)
    setRegin(copyProvince, city, area)
  }
  return <>
    <Picker
      data={regions}
      disabled={disabled}
      cols={3}
      onChange={(v) => changeRegin(v)}
    >
      <div className="category-content_wrapper">
        <div className="title" style={{width: 62}}>
          {title} <i className="red">*</i>
        </div>
        <div className="content">
          <div className="my-input">
            {chooseName}
          </div>
          <i className='iconfont' style={{transform: 'rotate(90deg)'}}>&#xe6f8;</i>
        </div>
      </div>
    </Picker>
  </>
}

export default SbuBranchAreaPicker
