/*
 * @Author: cc
 * @Date: 2020-10-24 16:06:12
 * @LastEditors: cc
 * @LastEditTime: 2020-10-24 16:14:24
 * @Description: X.X
 */
import React, { useMemo, useReducer, useState, useEffect, useRef } from 'react'
import { Tabs, Badge, Toast, Modal } from "antd-mobile"
import './index.less'

import { useHistory, useLocation } from "react-router-dom"
import { useQuery, useMutation } from "react-apollo"
import { MY_CONFIG } from "@/graphql/login"

const Resources = () => {
  const { search } = useLocation()
  const [res, setRes] = useState('')
  const { data } = useQuery(MY_CONFIG, {
    variables:{
      name: search.slice(6)
    },
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (data.myConfig) {
        setRes(data.myConfig)
      }
    },
  })

  return <div className='resources'>
    <div dangerouslySetInnerHTML={{ __html: res }} />
  </div>
}

export default Resources